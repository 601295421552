import React from 'react';
import styled from 'styled-components';

import {
  Button,
  GreenButton,
  CourseCard,
  CourseThumbnail,
  Notification,
  Stat,
  CourseRegPlus,
  CourseRegItem,
} from '../components/common';

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
`;

const SectionTitle = styled.h1`
  font-size: 2.7rem;
  margin-bottom: 4rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5rem;
  align-items: flex-start;
`;

function Components() {
  return (
    <Wrapper>
      <SectionTitle>Buttons</SectionTitle>
      <Row>
        <Button>Base</Button>
        <GreenButton>Theme Button</GreenButton>
      </Row>
      <SectionTitle>Cards</SectionTitle>
      <Row>
        <CourseCard />
        <CourseThumbnail />
        <Notification />
        <Stat />
      </Row>
      <Row>
        <CourseRegPlus />
      </Row>
      <Row>
        <CourseRegItem />
      </Row>
      <Row>
        <CourseRegItem state={true} />
      </Row>
    </Wrapper>
  );
}

export default Components;
