import React from "react";
import styled from "styled-components";

import { ReactComponent as Course } from "../../assets/images/icons/course.svg";
import dot from "../../assets/images/icons/notification-dot.svg";
import menu from "../../assets/images/icons/notification-menu.svg";

const Box = styled.div`
  width: 410px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
`;

const Icon = styled(Course)`
  align-self: flex-start;
`;

const Detail = styled.div`
  width: 185px;
  h2 {
    color: ${({ theme: { gaca } }) => gaca.gacaGreen};
    font-size: 1.6rem;
  }

  p {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    line-height: 1.4;
    color: #aeaeae;
  }
`;

const Time = styled.p`
  align-self: flex-end;
  color: #aeaeae;
  font-size: 1rem;
`;

function Notification() {
  return (
    <Box>
      <Icon />
      <Detail>
        <h2>Course Registration</h2>
        <p>
          Olasunkanmi Osho just registered for ST-Serv1: Simatic S7 Service...
        </p>
      </Detail>

      <Time>Just now</Time>
      <img src={dot} alt='notification dot' />
      <img src={menu} alt='notification menu' />
    </Box>
  );
}

export { Notification };
