import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { Loader, ErrorOccured } from '../components/common'
import { AddTutor, EditTutor, TutorCard } from '../components/tutors'

import { addTutor, getTutors, editTutor } from '../queries/tutors'

function AddTutors() {
  const { isLoading, data, isError } = useQuery('registrations', getTutors, {
    retry: 1,
  })

  const [tutor, setTutor] = useState(null)
  const [loading, setLoading] = useState(false)
  const [blobImage, setblobImage] = useState(null)
  const [file, setFile] = useState(null)
  const form = useRef()

  const saveTutor = async (e) => {
    e.preventDefault()

    setLoading(true)

    try {
      await addTutor(form, file)

      toast('Tutor Added Successfully')
      setblobImage(null)
      setFile(null)
    } catch (error) {
      toast('Sommething went wrong')
      setLoading(false)
    }
  }

  const updateTutor = async () => {
    setLoading(true)

    try {
      await editTutor(tutor, file)

      toast('Tutor updated Successfully')
      setblobImage(null)
      setFile(null)
      setTutor(null)
    } catch (error) {
      console.log(error.response)
      toast(error.response.data.message || error.message)
      setLoading(false)
    }
  }

  if (data) console.log(data)

  if (isLoading || loading) {
    return <Loader />
  }

  if (isError) {
    return <ErrorOccured />
  }

  return (
    <Wrapper>
      <Body>
        {tutor ? (
          <EditTutor
            tutor={tutor}
            setTutor={setTutor}
            setFile={setFile}
            setblobImage={setblobImage}
            updateTutor={updateTutor}
          />
        ) : (
          <AddTutor
            saveTutor={saveTutor}
            blobImage={blobImage}
            setblobImage={setblobImage}
            setFile={setFile}
            form={form}
          />
        )}

        <TutorCards>
          {data.map((tutor) => (
            <TutorCard key={tutor.id} tutor={tutor} setTutor={setTutor} />
          ))}
        </TutorCards>
      </Body>
    </Wrapper>
  )
}

export default AddTutors

const Wrapper = styled.div`
  background: #f2f6f8;
  width: 100%;
  padding: 2rem;
`

const Body = styled.div`
  border-radius: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

const TutorCards = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
