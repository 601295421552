import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import JoditEditor from "jodit-react";

import {
  Button,
  Loader,
  ErrorOccured,
  CourseImageUpload,
} from '../components/common'

import { addCourse, getCategories } from '../queries/courses'
import { addBlog } from '../queries/blog'
import { getBlogCategories } from '../queries/blog'
import TextEditor from '../components/common/TextEditor'

function AddBlog() {
    
  const { isLoading, data: categoryData, isError } = useQuery('categories', getBlogCategories, {
    retry: 1,
  })

  console.log(categoryData, 'categoryData')

//   const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const form = useRef()
  const history = useHistory()
  const editor = useRef(null)

  const config = {
      readonly: false // all options from https://xdsoft.net/jodit/doc/
  }

//   const [state, setState] = useState(false)
    const [image, setImage] = React.useState(null);
    const [subTitle, setSubTitle] = useState('')
    const [category, setcategory] = useState('')
    const [title, setTitle] = useState('')
    const [blogOverview, setBlogOverview] = useState('')
    const [blogContent, setBlogContent] = useState('')
    const [blogContent2, setBlogContent2] = useState('')
    const [note, setNote] = useState('')
    const [author, setAuthor] = useState('')
    const [content, setcontent] = useState('')
    const [tags, setTags] = useState([])

    console.log(content, 'ddd')

  const saveCourse = async (e) => {
    e.preventDefault()

    let formData = {
        image,
        content,
        title,
        tags,
        category,
        author
    }

    // addBlog(formData)
    // console.log(formData)

    if (!image) {
      alert('Please add an image')
      return
    }

    try {
      setLoading(true)
      await addBlog(formData)
      toast('Blog added successfuly')
      setLoading(false)

      history.push('/blog')
    } catch (error) {
      setError(true)
    }
  }

  if (loading || isLoading) {
    return <Loader />
  }

  if (error || isError) {
    return <ErrorOccured />
  }

  return (
    <Wrapper>
      <Body ref={form} onSubmit={saveCourse}>
        <Left>
          <Box>
            <Header>Add New Blog</Header>
            <TitleBox>
              <CourseTitle 
                  required 
                  name="title" 
                  placeholder="Blog Title" 
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
              />
            </TitleBox>
          </Box>
          <Box>
            {/* <TextEditor setcontent={setcontent} /> */}
            {/* <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setcontent(data)
                    } }
                /> */}
                <JoditEditor
                  ref={editor}
                  // value={content}
                  // config={config}
                  tabIndex={1} // tabIndex of textarea
                  onChange={newContent => setcontent(newContent)}
                  onBlur={newContent => setcontent(newContent.target.innerHTML)}
                />
          </Box>
        </Left>
        <Right>
          <Box>
            <Header>Actions</Header>
            <CourseImageUpload image={image} setImage={setImage} />
            <Label>Author</Label>
            <TitleBox>
              <CourseTitle 
                  required 
                  name="author" 
                  placeholder="Blog Author" 
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
              />
            </TitleBox>
            <GreenButton type="submit">Publish</GreenButton>
          </Box>
          <Box>
          <Header>Blog Tags</Header>
              <TagsTitle 
                name="tag" 
                placeholder="Tags"
                value={tags}
                onChange={(e) => setTags(e.target.value.replace(/\s/g, "").split(","))}
              />
              <div style={{width: '85%', margin: '0 auto'}}>
                <p className="sep mt-1">Separate tags with commas</p>
                    <div className="mt-2">
                        {tags != '' && (
                            <div>
                                {tags?.map((item, index) => (
                                    <a key={index} href="#" class="badge cat_badge">
                                        {item} X
                                    </a>
                                ))}
                            </div>
                        )}
                        
                    </div>
                </div>
          </Box>
          <Box>
            <Header>Blog Category</Header>
            {categoryData?.map((category) => (
              <CourseSpan key={category.id}>
                <input
                  required
                  name="category"
                  type="radio"
                  id={category.value}
                  value={category.id}
                  onClick={(e) => setcategory(category._id)}
                />
                <label htmlFor={category.value}>{category.name}</label>
              </CourseSpan>
            ))}
          </Box>
        </Right>
      </Body>
    </Wrapper>
  )
}

export default AddBlog

const Wrapper = styled.div`
  background: #f2f6f8;
  width: 100%;
  padding: 2rem;
`

const Body = styled.form`
  height: 100%;
  display: flex;
  justify-content: space-between;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Left = styled.div`
  width: 74%;
`

const Right = styled.div`
  width: 25%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 0.5rem;
  padding: 2rem 0;
  margin-bottom: 2rem;
`
const Header = styled.h1`
  font-size: 2rem;
  padding: 2rem;
  padding-top: 0;
  border-bottom: 1px solid #e7e9ee;
  font-weight: bold;
`

const Input = styled.input`
  background: #f2f2f2;
  border: 1px solid #e3e3e3;
  padding: 1.8rem;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 16px;
    color: #aeaeae;
  }
`

const CourseTitle = styled(Input)`
  width: 100%;
`


const TagsTitle = styled(Input)`
  width: 85%;
  margin: 0 auto;
  margin-top: 2rem;
`

const SubTitle = styled(Input)`
  width: 22%;
`

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const Label = styled.label`
  font-size: 1.6rem;
  padding: 2rem 2rem 0rem 2rem;
`;

const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
`

const StartDate = styled(Input)`
  width: 38.25%;
`
const EndDate = styled(Input)`
  width: 38.25%;
`
const Duration = styled(Input)`
  width: 22%;
`

const FullWidths = styled.textarea`
  background: #f2f2f2;
  border: 1px solid #e7e9ee;
  border-radius: 3px;
  padding: 1.8rem;
  margin: 0 2rem;
  height: 160px;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 16px;
    color: #aeaeae;
  }
`

const HalfWidths = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 2rem;
  :first-of-type {
    padding-top: 2rem;
  }

  textarea {
    width: 49.5%;
    background: #f2f2f2;
    border: 1px solid #e7e9ee;
    border-radius: 3px;
    padding: 1.8rem;
    height: 160px;
    font-family: 'AvenirLTStd';

    &::placeholder {
      font-size: 16px;
      color: #aeaeae;
    }
  }
`

const WhiteButton = styled(Button)`
  width: 100%;
  color: black;
  border: 1px solid #c4cdd5;
  background: #fff;
  border-radius: 3px;
  margin: 2rem 2rem 0;
  width: auto;
`

const GreenButton = styled(WhiteButton)`
  background: ${({ theme: { gaca } }) => gaca.gacaGreen};
  color: white;
`

const CourseSpan = styled.span`
  margin: 0 2rem 2rem;
  display: flex;
  align-items: center;

  :first-of-type {
    margin-top: 2rem;
  }
  input {
    margin-right: 1rem;
  }
`
