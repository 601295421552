import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import { CourseRegItem, GreenButton } from '../common';

import { Table } from '../common';
import { StatusBtn } from '../../pages/Registrations';

import { Wrapper, Container, Box } from '../common/CourseRegListUI';
import { Link } from 'react-router-dom';

function Registrations({ registrations }) {
  const columns = useMemo(() => [
    { Header: 'Full name', accessor: 'name', width: '20%' },
    { Header: 'Course title', accessor: 'course.title', width: '30%' },
    { Header: 'Email address', accessor: 'email', width: '18%' },
    { Header: 'Phone', accessor: 'phone', width: '10%' },
    {
      Header: 'Date',
      accessor: 'createdAt',
      width: '8%',
      Cell: (tableProps) => moment(tableProps.value).format('l'),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '10%',
      Cell: (tableProps) => {
        // button changes to 'Approve' on hover if pending...
        return (
          <StatusBtn status={tableProps.value} data={tableProps.row.original} />
        );
      },
    },
  ]);

  const tableData = useMemo(() => registrations);

  return (
    <Body>
      <Top>
        <Header>Latest Pending Registration</Header>
        <Link to="/registrations">
          <GreenButton>See all Registration</GreenButton>
        </Link>
      </Top>
      <Table data={tableData} columns={columns} />
    </Body>
  );
}

export { Registrations };

const Body = styled.div`
  background: white;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 6rem 2rem;
`;

const Header = styled.h1`
  z-index: 10;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Avenir Heavy', sans-serif;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  padding: 0 3.5rem;
`;

const NewBox = styled(Box)`
  justify-content: flex-start;
  font-weight: ${({ header }) => (header ? 'bold' : 'normal')};
`;
