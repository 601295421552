import React from 'react'
import styled from 'styled-components'

import serverdown from '../../assets/images/server_down.svg'

function ErrorOccured() {
  return (
    <Wrapper>
      <img src={serverdown} alt="server down" />
      <h1>Something went wrong. Please refresh page</h1>
    </Wrapper>
  )
}

export { ErrorOccured }

const Wrapper = styled.div`
  padding: 6.4rem 4rem;
  background-color: #f1f6f7;
  width: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 500px;
  }

  h1 {
    font-size: 2rem;
    margin-top: 2rem;
  }
`
