import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import moment from 'moment';

import {
  CourseRegItem,
  Loader,
  ErrorOccured,
  Wrapper,
  Container,
  Box,
  BounceLoader,
  Table,
} from '../components/common';

import { getRegistrations } from '../queries/enrolled';
import { approve } from '../queries/home';

const StatusBtn = ({ status, data }) => {
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentstatus] = useState(status);

  const handleStatusChange = async () => {
    const { id, email } = data;
    setLoading(true);
    try {
      await approve(id, email);
      setCurrentstatus('APPROVED');
      setLoading(false);
    } catch {
      toast.error('Something went wrong!');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const style = `
  color: ${currentStatus === 'PENDING' ? '#8a8a8a' : '#fff'};
  background: ${currentStatus === 'PENDING' ? '#e2dddd' : '#2DAEE5'};
  border-radius: 2px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 10px 10px;
  border: 0;
  width: 80px;

  & .hover-show {
    display: none;
  }

  ${
    currentStatus === 'PENDING' &&
    !loading &&
    css`
      &:hover {
        background: #5aba5e;
        color: #fff;
      }

      &:hover .hover-show {
        display: initial;
      }

      &:hover .main-text {
        display: none;
      }
    `
  }
  `;

  return (
    <button
      css={style}
      onClick={handleStatusChange}
      disabled={loading || currentStatus !== 'PENDING' ? true : false}
    >
      <BounceLoader loading={loading} />
      {!loading && (
        <>
          <span
            className="main-text"
            css={`
              text-transform: capitalize;
            `}
          >
            {currentStatus.toLowerCase()}
          </span>
          <span className="hover-show">Approve</span>
        </>
      )}
    </button>
  );
};

function Registrations() {
  const { isLoading, data, isError } = useQuery(
    'registrations',
    getRegistrations,
    {
      retry: 1,
    }
  );

  const columns = useMemo(() => [
    { Header: 'Full name', accessor: 'name', width: '20%' },
    { Header: 'Course title', accessor: 'course.title', width: '30%' },
    { Header: 'Email address', accessor: 'email', width: '18%' },
    { Header: 'Phone', accessor: 'phone', width: '10%' },
    {
      Header: 'Date',
      accessor: 'createdAt',
      width: '8%',
      Cell: (tableProps) => moment(tableProps.value).format('l'),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '10%',
      Cell: (tableProps) => {
        // button changes to 'Approve' on hover if pending...
        return (
          <StatusBtn status={tableProps.value} data={tableProps.row.original} />
        );
      },
    },
  ]);

  const tableData = useMemo(() => data);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  return (
    <Wrap>
      <Body>
        <Top>
          <Header>Pending Registrations</Header>
        </Top>
        <Table data={tableData} columns={columns} />
      </Body>
    </Wrap>
  );
}

export default Registrations;
export { StatusBtn };
// loader

// disable on approved state

const Wrap = styled.div`
  background: #f2f6f8;
  width: 100%;

  padding: 2rem;
`;

const Body = styled.div`
  background: white;
  border-radius: 0.5rem;
  height: 100%;
  display: flex;
  padding: 32px;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.h1`
  z-index: 10;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Avenir Heavy', sans-serif;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
`;
