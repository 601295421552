import instance from './axios'
import axios from 'axios'

export const getUsers = async () => {
  const { data } = await instance.get(`/users`)

  return data
}

export const deleteUser = async (id) => {
  await instance.delete(`/users/${id}`)
}

export const addUser = async (userData, password) => {
  const { data } = await instance.post('/users', {
    ...userData,
    role: '5ed41430f7fb3b20b0ed6bb6',
    confirmed: true,
    blocked: false,
    provider: 'local',
    password,
  })

  const { data: emailRes } = await axios.get(
    'https://us-central1-gaca-e75ac.cloudfunctions.net/sendLoginDetails',
    {
      params: {
        email: userData.email,
        password,
      },
    }
  )

  console.log(emailRes)

  return data
}
