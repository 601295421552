import React from 'react'
import styled from 'styled-components'

import trash from '../../assets/images/icons/trash.svg'

function UsersList({ users, toggleDelModal }) {
  console.log(users)

  return (
    <>
      {users.map((user) => (
        <List key={user.id}>
          <section>
            <div>
              <span>{user.username.slice(0, 2)}</span>
            </div>
            <Flex>
              <h2>{user.username}</h2>
              <p> {user.email}</p>
            </Flex>
          </section>
          <img onClick={() => toggleDelModal(user.id)} src={trash} alt="" />
        </List>
      ))}
    </>
  )
}

export { UsersList }

const List = styled.div`
  background: #f9fafb;
  max-width: 80%;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 1rem;

  span {
    background: #ffc0e6;
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #f91ea1;
    font-size: 1.8rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  section {
    margin-left: 4rem;
    display: flex;
    align-items: center;
  }

  img {
    width: 20px;
  }
`

const Flex = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 2rem;

  h2 {
    font-size: 2rem;
    margin-bottom: 0.4rem;
    /* font-weight: bold; */
  }

  p {
    color: #c5ccd2;
  }
`
