import styled from 'styled-components';

const Row = styled.div`
  width: 100;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

const Box = styled.div`
  width: ${({ width }) => (width ? width : 'auto')};
`;

export { Row, Box };
