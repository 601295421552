import React from 'react'
import styled from 'styled-components'

const Pagination = ({ postsPerPage, currentPage, totalPosts, paginate }) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <Wrapper>
      <Pages>
        <p onClick={() => paginate(currentPage - 1)}>Back</p>
        {pageNumbers.map((number) => (
          <p
            className={currentPage === number ? 'active' : ''}
            key={number}
            onClick={() => paginate(number)}
          >
            {number}
          </p>
        ))}
        <p onClick={() => paginate(currentPage + 1)}>Next</p>
      </Pages>
    </Wrapper>
  )
}

export { Pagination }

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Pages = styled.div`
  font-size: 1.2rem;
  margin: 3rem 5rem;

  p {
    display: inline-flex;
    padding: 1rem;
    border-radius: 2px;
    cursor: pointer;

    &.active {
      background: ${({ theme: { gaca } }) => gaca.gacaGreen};
      color: #fff;
    }
  }
`
