import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import off from '../../assets/images/icons/off.svg'
// import search from '../../assets/images/icons/search.svg'
import profile from '../../assets/images/icons/avatar.svg'
import notification from '../../assets/images/icons/notification-dot.svg'
import { AuthContext, LOGOUT } from '../../contexts/auth'

const Container = styled.div`
  width: calc(100% - 175px);
  height: 7rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 5;

  .dot {
    padding-top: 1rem;
  }
`

// const SearchBox = styled.div`
//   width: 30%;
//   margin-left: 10%;

//   input {
//     width: 90%;
//     height: 4rem;
//     border: none;
//     border-radius: 2rem;
//     margin-left: 1rem;
//     font-size: 1.4rem;
//     color: #76839d;
//     padding: 1rem;

//     &::placeholder {
//       color: #76839d;
//     }
//   }
// `

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  margin-right: 5rem;

  img {
    cursor: pointer;
  }
`

const UserMenu = styled.div`
  width: 200px;
  height: 130px;
  right: 170px;
  top: 70px;
  background: #fff;
  box-shadow: 2px 4px 10px rgba(51, 51, 51, 0.1);
  border-radius: 1px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

function Topbar() {
  const [showUserMenu, setShowUserMenu] = useState(false)
  const { dispatch } = useContext(AuthContext)

  return (
    <Container>
      {/* <SearchBox>
        <img src={search} alt="searchIcon" />
        <input type="text" placeholder="search" />
      </SearchBox> */}
      <Icons>
        <img
          onClick={() => setShowUserMenu(!showUserMenu)}
          src={profile}
          alt=""
        />
        <img src={notification} alt="" className="dot" />
      </Icons>
      {showUserMenu && (
        <UserMenu>
          <img onClick={() => dispatch({ type: LOGOUT, payload: null})} src={off} alt="" />
        </UserMenu>
      )}
    </Container>
  )
}

export { Topbar }
