import React from 'react'
import moment from 'moment'

import { Wrapper, Container, Box } from './CourseRegListUI'
import styled from 'styled-components'
import option from '../../assets/images/icons/option.svg'
import { Link } from 'react-router-dom'

const NewBox = styled(Box)`
  justify-content: flex-start;
`

const truncateString = (str) => {
  if (!str) return ''
  if (str.length < 55) return str
  return str.slice(0, 47) + '...'
}

function capitalize(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

function BlogTable({ id, reg, openModal, handleBlogDelete, index, openDEModal, showEDModal }) {
  return (
    <Wrapper>
      <Container>
        <NewBox width="12%">{capitalize(reg && reg.title)}</NewBox>
        <NewBox width="25%">{reg && reg.author}</NewBox>
        {/* <NewBox width="18%">{reg.email}</NewBox> */}
        <NewBox width="8%">
            <img src={reg && reg.photo} width="50px" height="30px" />
        </NewBox>
        <NewBox width="8%">
          {' '}
          {moment(reg && reg.createdAt).format('l')}
        </NewBox>
        <NewBox width="5%">
          <Button onClick={() => openModal(id)}>View Details</Button>
        </NewBox>
        <NewBox width="5%">
          {/* <Button onClick={() => handleBlogDelete(id)}></Button> */}
          <img src={option} alt="" onClick={() => openDEModal(index)}/>
        </NewBox>
        {showEDModal === index && (
            <div style={{padding: '1rem', backgroundColor: '#fff', position: 'absolute', bottom: '-10px', right: 0,zIndex: 1000000000}}>
                <p>
                    <Link to={`/blog/edit/${id}`}>
                        Edit
                    </Link>
                </p>
                <p style={{color: 'red'}} onClick={() => handleBlogDelete(id)}>Delete</p>
            </div>
        )}
      </Container>
      {/* {console.log(reg, 'registration')} */}
    </Wrapper>
  )
}
export { BlogTable }

const Button = styled.button`
  width: 100%;
  height: 3rem;
  /* padding: 1rem; */
  color: #fff;
  border: none;
  border-radius: 2px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  background: ${({ theme: { gaca } }) => gaca.gacaBlue};
`
