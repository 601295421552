import React from 'react';
import styled from 'styled-components';

import { GradientButton } from './Buttons';
import { CardBox, Img, Info, Category, Tutor, Title } from './CardUI';

import courseImg from '../../assets/images/courseimg.jpeg';

const CardWrapper = styled(CardBox)`
  padding: 2rem;
`;

function CourseCard() {
  return (
    <CardWrapper>
      <Img src={courseImg} alt=" industrial course" />
      <Info>
        <Category color="gacaBlue">Indutrial course</Category>
        <Tutor>Eyitayo Ayeni</Tutor>
      </Info>
      <Title>ST-SERVICE 1: SEMATIC S7 SERVICE and Mentainace (Course)</Title>
      <GradientButton style={{ width: '100%' }}>Modify</GradientButton>
    </CardWrapper>
  );
}

export { CourseCard };
