import styled from "styled-components";

const Wrapper = styled.div`
  display: block;
  width: 100%;
  &:nth-of-type(even) > div {
    background: #f9fafb;
  }
  /* z-index: 0; */
`;

const Container = styled.div`
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  background: #fff;
  position: relative;
  z-index: 1
`;

const Box = styled.div`
  width: ${({ width }) => (width ? width : "auto")};
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Wrapper, Container, Box };
