import styled from 'styled-components'
import React from 'react'

import { Sidebar } from './Sidebar'
import { Topbar } from './Topbar'

const Page = styled.div`
  display: flex;
  background: #f2f6f8;
`

const PageContent = styled.div`
  margin-left: 175px;
  width: auto;
  flex: 1;
  min-height: calc(100vh - 7rem);
`

const Center = styled.div`
  width: 100%;
  max-width: calc(1440px - 175px);
  margin: auto;
  padding-top: 5rem;
`

const Children = styled.div`
  margin-top: 7rem;
  width: 100%;
`

function PageWrapper({ children }) {
  return (
    <Page>
      <Sidebar />
      <PageContent>
        <Topbar />
        <Center>
          <Children>{children}</Children>
        </Center>
      </PageContent>
    </Page>
  )
}

export { PageWrapper }
