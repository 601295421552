import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createBrowserHistory } from 'history';

// Pages
import {
  Home,
  Login,
  Feedbacks,
  Courses,
  EmptyCourses,
  Registrations,
  Users,
  Notifications,
  Enrollment,
  Components,
  ChooseLogin,
  AddCourse,
  Tutors,
  EditCourse,
  BlogsList,
  AddBlog,
  EditBlog,
} from './pages';

import { PageWrapper } from './components/common';

import { AuthContextProvider, AuthContext } from './contexts/auth';

export const history = createBrowserHistory();

const theme = {
  gaca: {
    gacaGreen: '#5ABA5E;',
    gacaGradient: 'linear-gradient(274.79deg, #5BBB5F 13.75%, #35AF76 89.83%)',
    gacaOrange: '#F99F1E',
    orangeGradient: 'linear-gradient(180deg, #F09819 20.83%, #CA7904 83.33%)',
    orangeGradient2: 'gradient(180deg, #FFB955 4%, #FF9A02 80.67%)',
    gacaBlue: '#2DAEE5',
    subtleGrey: '#AEAEAE',
    silverGrey: '#76839D',
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <ToastContainer
        // toastClassName="notification"
        // progressClassName="notification-bar"
        />
        <Router history={history}>
          <Switch>
            <Route exact path="/chooselogin">
              <ChooseLogin />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <PageWrapper>
              <PrivateRoute path="/" exact>
                <Home />
              </PrivateRoute>
              <PrivateRoute path="/feedbacks" exact>
                <Feedbacks />
              </PrivateRoute>
              <PrivateRoute path="/courses/add" exact>
                <AddCourse />
              </PrivateRoute>
              <PrivateRoute path="/courses/edit/:id" exact>
                <EditCourse />
              </PrivateRoute>
              <PrivateRoute path="/courses" exact>
                <Courses />
              </PrivateRoute>
              <PrivateRoute path="/courses/empty" exact>
                <EmptyCourses />
              </PrivateRoute>
              <PrivateRoute path="/registrations" exact>
                <Registrations />
              </PrivateRoute>
              <PrivateRoute path="/users" exact>
                <Users />
              </PrivateRoute>
              <PrivateRoute path="/tutors" exact>
                <Tutors />
              </PrivateRoute>
              <PrivateRoute path="/notifications" exact>
                <Notifications />
              </PrivateRoute>
              <PrivateRoute path="/enrollment" exact>
                <Enrollment />
              </PrivateRoute>
              <PrivateRoute path="/blog" exact>
                <BlogsList />
              </PrivateRoute>
              <PrivateRoute path="/blog/add" exact>
                <AddBlog />
              </PrivateRoute>
              <PrivateRoute path="/blog/edit/:id" exact>
                <EditBlog />
              </PrivateRoute>
              <PrivateRoute path="/components" exact>
                <Components />
              </PrivateRoute>
            </PageWrapper>
          </Switch>
        </Router>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { authState } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState?.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
