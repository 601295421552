import styled from 'styled-components';

const Button = styled.button`
  width: ${({ width }) => width || '180px'};
  height: 45px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 1.4rem;
  margin-right: 1rem;
`;

const GreenButton = styled(Button)`
  background: ${({ theme: { gaca } }) => gaca.gacaGreen};
`;

const GradientButton = styled(Button)`
  background: ${({ theme: { gaca } }) => gaca.gacaGradient};
`;

export { Button, GreenButton, GradientButton };
