import React from 'react'
import styled from 'styled-components'

function TrendingCard({ items, title }) {
  return (
    <>
      <Trending>
        <Header>
          <Title>{title}</Title>
        </Header>
        <List>
          {items?.map((item, i) => (
            <Item key={i}>
              <p>{item.name}</p>
              <p>{item.count}</p>
            </Item>
          ))}
        </List>
      </Trending>
    </>
  )
}

export { TrendingCard }

const Trending = styled.div`
  background: #ffffff;
  border: 1px solid #e7e9ee;
  border-radius: 5px;
  width: 275px;
  padding: 3rem 1.7rem;
  min-height: 320px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.4rem;
`

const Title = styled.h3`
  font-size: 1.2rem;
  color: #76839d;
  font-family: 'Avenir Heavy', sans-serif;
`

const List = styled.ul``

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #ececec;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #333333;
  padding: 15px 0;
`
