import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import { updateCourse, getCategories } from '../queries/courses'
import instance from '../queries/axios'

import {
  Button,
  Loader,
  CourseImageUpload,
  ErrorOccured,
} from '../components/common'

function EditCourse() {
  const { isLoading, data, isError } = useQuery('categories', getCategories, {
    retry: 1,
  })


  let { id } = useParams()

  const getCourse = async (id) => {
    setLoading(true)
    const { data } = await instance.get(`/courses/${id}`)
    setLoading(false)
    return setCourse(data)
  }


  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [image, setImage] = useState(null)
  const [course, setCourse] = useState(null)
  const form = useRef()
  const history = useHistory()

  useEffect(() => {
    // data && data.map((item) => {
    //   return item.courses.map(item => {
    //     setCourse(item)
    //   })
    // })
    getCourse(id)
  }, [data])

  console.log(course, 'course')

  async function saveCourse(e) {
    e.preventDefault()

    // if (!image) {
    //   toast('Please add an image')
    //   return
    // }

    try {
      setLoading(true)
      await updateCourse(form, image, id)
      toast('Course added successfuly')
      setLoading(false)
      // console.log(form.current)

      history.push('/courses')
    } catch (error) {
      setError(true)
    }
  }

  if (loading || isLoading || course === null) {
    return <Loader />
  }

  if (error || isError) {
    return <ErrorOccured />
  }


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Wrapper>
          <Body ref={form} onSubmit={saveCourse}>
            <Left>
              <Box>
                <Header>Add New Course</Header>

                <TitleBox>
                  <CourseTitle
                    required
                    name="title"
                    placeholder="Course Title"
                    value={course.title}
                    onChange={(e) =>
                      setCourse({ ...course, title: e.target.value })
                    }
                  />
                  <SubTitle
                    required
                    name="subtitle"
                    value={course.subtitle}
                    onChange={(e) =>
                      setCourse({ ...course, subtitle: e.target.value })
                    }
                    placeholder="Sub Title"
                  />
                </TitleBox>
                <DateBox>
                  <StartDate
                    value={course.start}
                    onChange={(e) =>
                      setCourse({ ...course, start: e.target.value })
                    }
                    required
                    name="start"
                    placeholder="Start Date"
                  />
                  <EndDate
                    value={course.end}
                    onChange={(e) =>
                      setCourse({ ...course, end: e.target.value })
                    }
                    required
                    name="end"
                    placeholder="End Date"
                  />
                  <Duration
                    value={course.duration}
                    onChange={(e) =>
                      setCourse({ ...course, duration: e.target.value })
                    }
                    required
                    name="duration"
                    placeholder="Duration"
                  />
                </DateBox>
              </Box>
              <Box>
                <FullWidths
                  required
                  name="overview"
                  placeholder="Course Overview"
                  value={course.overview}
                  onChange={(e) =>
                    setCourse({ ...course, overview: e.target.value })
                  }
                />
                <HalfWidths>
                  <textarea
                    required
                    name="attendees"
                    placeholder="Who Should Attend"
                    value={course.attendees}
                    onChange={(e) =>
                      setCourse({ ...course, attendees: e.target.value })
                    }
                  />
                  <textarea
                    required
                    name="benefits"
                    placeholder="Courses Benefits"
                    value={course.benefits}
                    onChange={(e) =>
                      setCourse({ ...course, benefits: e.target.value })
                    }
                  />
                </HalfWidths>
                <HalfWidths>
                  <textarea
                    required
                    name="content"
                    placeholder="Course Content"
                    value={course.content}
                    onChange={(e) =>
                      setCourse({ ...course, content: e.target.value })
                    }
                  />
                  <textarea
                    name="prerequisites"
                    placeholder="Participant Pre Requirements"
                    value={course.prerequisites}
                    onChange={(e) =>
                      setCourse({ ...course, prerequisites: e.target.value })
                    }
                  />
                </HalfWidths>
                <FullWidths
                  required
                  name="notes"
                  value={course.notes}
                  placeholder="Notes"
                  onChange={(e) =>
                    setCourse({ ...course, notes: e.target.value })
                  }
                />
              </Box>
            </Left>
            <Right>
              <Box>
                <Header>Actions</Header>
                <CourseImageUpload setImage={setImage} image={image} />
                {/* <WhiteButton>Preview</WhiteButton> */}
                {/* <WhiteButton type="submit">Save as draft</WhiteButton> */}
                <GreenButton type="submit">Publish</GreenButton>
              </Box>
              <Box>
                <Header>Course Category</Header>
                {data.map((category) => (
                  <CourseSpan key={category.id}>
                    <input
                      required
                      name="category"
                      type="radio"
                      id={category.value}
                      value={category.id}
                      checked={ category.id === course.category.id}
                      onChange={(e) =>
                        setCourse({
                          ...course,
                          category: {
                            ...course.category,
                            id: e.target.value,
                            _id: e.target.value,
                          },
                        })
                      }
                    />
                    <label htmlFor={category.value}>
                      {category.name} Course
                    </label>
                  </CourseSpan>
                ))}
                {/* <GreenText>Add new Category</GreenText>

                <WhiteButton type="button">Save</WhiteButton> */}
              </Box>
              {/* <LastBox>
                <Header>Banner - Upcoming Trainings</Header>
                <DashedBox>
                  <img src={uploadImg} alt="" />
                  <WhiteButton type="button">Featured Image</WhiteButton>
                </DashedBox>
                <CourseTitleInput
                  style={{ width: 'auto' }}
                  placeholder="Course Title"
                  type="text"
                />
                <DateBox>
                  <input placeholder="Start Date" />
                  <input placeholder="End Date" />
                </DateBox>
                <GreenButton type="button">Publish</GreenButton>
              </LastBox> */}
            </Right>
          </Body>
        </Wrapper>
      )}
    </>
  )
}

export default EditCourse

const Wrapper = styled.div`
  background: #f2f6f8;
  width: 100%;

  padding: 2rem;
`

const Body = styled.form`
  height: 100%;
  display: flex;
  justify-content: space-between;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Left = styled.div`
  width: 74%;
`

const Right = styled.div`
  width: 25%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 0.5rem;
  padding: 2rem 0;
  margin-bottom: 2rem;
`

const Header = styled.h1`
  font-size: 2rem;
  padding: 2rem;
  padding-top: 0;
  border-bottom: 1px solid #e7e9ee;
  font-weight: bold;
`

const Input = styled.input`
  background: #f2f2f2;
  border: 1px solid #e3e3e3;
  padding: 1.8rem;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 16px;
    color: #aeaeae;
  }
`

const CourseTitle = styled(Input)`
  width: 77.25%;
`

const SubTitle = styled(Input)`
  width: 22%;
`

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
`

const StartDate = styled(Input)`
  width: 38.25%;
`
const EndDate = styled(Input)`
  width: 38.25%;
`
const Duration = styled(Input)`
  width: 22%;
`

const FullWidths = styled.textarea`
  background: #f2f2f2;
  border: 1px solid #e7e9ee;
  border-radius: 3px;
  padding: 1.8rem;
  margin: 0 2rem;
  height: 160px;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 16px;
    color: #aeaeae;
  }
`

const HalfWidths = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 2rem;
  :first-of-type {
    padding-top: 2rem;
  }

  textarea {
    width: 49.5%;
    background: #f2f2f2;
    border: 1px solid #e7e9ee;
    border-radius: 3px;
    padding: 1.8rem;
    height: 160px;
    font-family: 'AvenirLTStd';

    &::placeholder {
      font-size: 16px;
      color: #aeaeae;
    }
  }
`

const WhiteButton = styled(Button)`
  width: 100%;
  color: black;
  border: 1px solid #c4cdd5;
  background: #fff;
  border-radius: 3px;
  margin: 2rem 2rem 0;
  width: auto;
`

const GreenButton = styled(WhiteButton)`
  background: ${({ theme: { gaca } }) => gaca.gacaGreen};
  color: white;
`

const CourseSpan = styled.span`
  margin: 0 2rem 2rem;
  display: flex;
  align-items: center;

  :first-of-type {
    margin-top: 2rem;
  }
  input {
    margin-right: 1rem;
  }
`
