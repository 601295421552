import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Wrapper, Container, Box } from './CourseRegListUI'

import { approve } from '../../queries/home'
import { capitalize, truncateString } from '../../utils'

function CourseRegItem({ id, status, name, course, email, phone, createdAt }) {
  let action

  if (status === 'PENDING') {
    action = <Pending onClick={() => approve(id, email)}>Approve</Pending>
  } else if (status === 'APPROVED') {
    action = <Approved>Pending</Approved>
  } else {
    action = <Completed>Completed</Completed>
  }

  return (
    <Wrapper>
      <Container>
        <NewBox width="20%">{capitalize(name)}</NewBox>
        <NewBox width="30%">{truncateString(course?.title)}</NewBox>
        <NewBox width="18%">{email}</NewBox>
        <NewBox width="8%">{phone}</NewBox>
        <NewBox width="8%"> {moment(createdAt).format('l')}</NewBox>
        <NewBox width="auto">{action}</NewBox>
      </Container>
    </Wrapper>
  )
}
export { CourseRegItem }

const Button = styled.button`
  width: 100%;
  height: 3rem;
  color: #fff;
  border: none;
  border-radius: 2px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
`

const Pending = styled(Button)`
  background: ${({ theme: { gaca } }) => gaca.gacaGreen};
`

const Approved = styled(Button)`
  background: ${({ theme: { gaca } }) => gaca.gacaBlue};
`

const Completed = styled(Button)`
  background: ${({ theme: { gaca } }) => gaca.gacaOrange};
`

const NewBox = styled(Box)`
  justify-content: flex-start;
`
