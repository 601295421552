import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import slugify from 'slugify';

import {
  Button,
  Loader,
  ErrorOccured,
  CourseImageUpload,
} from '../components/common'

import { addCourse, getCategories } from '../queries/courses'

function AddCourse() {
  const { isLoading, data, isError } = useQuery('categories', getCategories, {
    retry: 1,
  })

  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  // const form = useRef()
  const history = useHistory()

  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    start: '',
    end: '',
    duration: '',
    overview: '',
    attendees: '',
    benefits: '',
    prerequisites: '',
    content: '',
    notes: '',
    category: '',
    // slug: ''
  })

  const {
    title,
    subtitle,
    start,
    end,
    duration,
    overview,
    attendees,
    benefits,
    prerequisites,
    content,
    notes,
    category,
    // slug
  } = formData


  const handleChange = e => {
    const { name, value } = e.target;
 
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const saveCourse = async (e) => {
    e.preventDefault()

    if (!image) {
      toast('Please add an image')
      return
    }

    const form = {
      title,
      subtitle,
      start,
      end,
      duration,
      overview,
      attendees,
      benefits,
      prerequisites,
      content,
      notes,
      slug: slugify(title),
      category
    }

    try {
      setLoading(true)
      await addCourse(form, image)
      toast('Course added successfuly')
      setLoading(false)

      history.push('/courses')
    } catch (error) {
      setError(true)
    }
  }

  if (loading || isLoading) {
    return <Loader />
  }

  if (error || isError) {
    return <ErrorOccured />
  }

  return (
    <Wrapper>
      <Body  onSubmit={saveCourse}>
      {/* ref={form} */}
        <Left>
          <Box>
            <Header>Add New Course</Header>

            <TitleBox>
              <CourseTitle required value={title} onChange={handleChange} name="title" placeholder="Course Title" />
              <SubTitle required value={subtitle} onChange={handleChange} name="subtitle" placeholder="Sub Title" />
            </TitleBox>
            <DateBox>
              <StartDate required name="start" placeholder="Start Date" value={start} onChange={handleChange} />
              <EndDate required name="end" placeholder="End Date" value={end} onChange={handleChange} />
              <Duration required name="duration" placeholder="Duration" value={duration} onChange={handleChange} />
            </DateBox>
          </Box>
          <Box>
            <FullWidths
              required
              name="overview"
              placeholder="Course Overview"
              onChange={handleChange}
              value={overview}
            />
            <HalfWidths>
              <textarea
                required
                name="attendees"
                placeholder="Who Should Attend"
                onChange={handleChange}
                value={attendees}
              />
              <textarea
                required
                name="benefits"
                placeholder="Courses Benefits"
                onChange={handleChange}
                value={benefits}
              />
            </HalfWidths>
            <HalfWidths>
              <textarea required name="content" placeholder="Course Content" value={content} onChange={handleChange} />
              <textarea
                name="prerequisites"
                placeholder="Participant Pre Requirements"
                onChange={handleChange}
                value={prerequisites}
              />
            </HalfWidths>
            <FullWidths required name="notes" placeholder="Notes" onChange={handleChange} value={notes} />
          </Box>
          {/* <CourseTitle required name="slug" value={} placeholder="Course Title" /> */}
        </Left>
        <Right>
          <Box>
            <Header>Actions</Header>
            <CourseImageUpload setImage={setImage} />
            <WhiteButton type="submit">Save as draft</WhiteButton>
            <GreenButton type="submit">Publish</GreenButton>
          </Box>
          <Box>
            <Header>Course Category</Header>
            {data.map((category) => (
              <CourseSpan key={category.id}>
                <input
                  required
                  name="category"
                  type="radio"
                  id={category.value}
                  value={category.id}
                  onChange={handleChange}
                />
                <label htmlFor={category.value}>{category.name} Course</label>
              </CourseSpan>
            ))}

            <WhiteButton type="button">Save</WhiteButton>
          </Box>
          {/* <CourseTitle disabled={true} value={slug} onChange={handleChange} name="slug" placeholder="Slug" /> */}
        </Right>
      </Body>
    </Wrapper>
  )
}

export default AddCourse

const Wrapper = styled.div`
  background: #f2f6f8;
  width: 100%;
  padding: 2rem;
`

const Body = styled.form`
  height: 100%;
  display: flex;
  justify-content: space-between;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Left = styled.div`
  width: 74%;
`

const Right = styled.div`
  width: 25%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 0.5rem;
  padding: 2rem 0;
  margin-bottom: 2rem;
`
const Header = styled.h1`
  font-size: 2rem;
  padding: 2rem;
  padding-top: 0;
  border-bottom: 1px solid #e7e9ee;
  font-weight: bold;
`

const Input = styled.input`
  background: #f2f2f2;
  border: 1px solid #e3e3e3;
  padding: 1.8rem;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 16px;
    color: #aeaeae;
  }
`

const CourseTitle = styled(Input)`
  width: 77.25%;
`

const SubTitle = styled(Input)`
  width: 22%;
`

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
`

const StartDate = styled(Input)`
  width: 38.25%;
`
const EndDate = styled(Input)`
  width: 38.25%;
`
const Duration = styled(Input)`
  width: 22%;
`

const FullWidths = styled.textarea`
  background: #f2f2f2;
  border: 1px solid #e7e9ee;
  border-radius: 3px;
  padding: 1.8rem;
  margin: 0 2rem;
  height: 160px;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 16px;
    color: #aeaeae;
  }
`

const HalfWidths = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 2rem;
  :first-of-type {
    padding-top: 2rem;
  }

  textarea {
    width: 49.5%;
    background: #f2f2f2;
    border: 1px solid #e7e9ee;
    border-radius: 3px;
    padding: 1.8rem;
    height: 160px;
    font-family: 'AvenirLTStd';

    &::placeholder {
      font-size: 16px;
      color: #aeaeae;
    }
  }
`

const WhiteButton = styled(Button)`
  width: 100%;
  color: black;
  border: 1px solid #c4cdd5;
  background: #fff;
  border-radius: 3px;
  margin: 2rem 2rem 0;
  width: auto;
`

const GreenButton = styled(WhiteButton)`
  background: ${({ theme: { gaca } }) => gaca.gacaGreen};
  color: white;
`

const CourseSpan = styled.span`
  margin: 0 2rem 2rem;
  display: flex;
  align-items: center;

  :first-of-type {
    margin-top: 2rem;
  }
  input {
    margin-right: 1rem;
  }
`
