import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'

import { useDropzone } from 'react-dropzone'

import {
  Title,
  Camera,
  UploadCard,
  TextInput,
  TextArea,
  AddButton,
  Loader,
  ErrorOccured,
} from '../components/common'

import { toast } from 'react-toastify'

import upload from '../assets/images/upload.svg'
import { FeedbackContent } from '../components/feedback/'
import { addFeedback, deleteFeedback, getFeedbacks } from '../queries/feedback'

function Feedbacks() {
  const { isLoading, data, isError } = useQuery('feedbacks', getFeedbacks, {
    retry: 1,
  })

  const form = useRef()
  const [blobImage, setblobImage] = useState(null)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleDelete = async (id) => {
    await deleteFeedback(id)
    toast('Deleted successfully')
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0])
      setblobImage(URL.createObjectURL(acceptedFiles[0]))
    },
  })

  const saveFeedback = async (e) => {
    e.preventDefault()

    setLoading(true)

    try {
      await addFeedback(form, file)
      toast('Feedback Added Successfully')

      setLoading(false)
    } catch (error) {
      console.log(error)
      toast('Something went wrong')
      setLoading(false)
    }
  }

  if (isLoading || loading) {
    return <Loader />
  }

  if (isError) {
    return <ErrorOccured />
  }

  return (
    <Wrapper>
      <UploadCard as="form" ref={form} onSubmit={saveFeedback}>
        <Title style={{ marginBottom: '2rem' }}>Feedbacks</Title>
        <ImageBox preview={blobImage} {...getRootProps()}>
          <input {...getInputProps()} />
          <Camera src={upload} alt="" />
        </ImageBox>
        <TextInput name="name" placeholder="Enter name"></TextInput>
        <TextArea name="comment" placeholder="Comment"></TextArea>
        <AddButton>Add</AddButton>
      </UploadCard>
      <ContentBox>
        <ContentWrapper>
          <Row style={{ background: '#F3F3F3', marginBottom: 0 }}>
            <Heading>Fullname</Heading>
            <Heading>Date</Heading>
            <div style={{ width: '10%' }} />
          </Row>
          {data.map((feedback) => (
            <>
              <FeedbackContent
                key={feedback.id}
                deleteFeedback={handleDelete}
                feedback={feedback}
              />
            </>
          ))}
        </ContentWrapper>
      </ContentBox>
    </Wrapper>
  )
}

export default Feedbacks

const ImageBox = styled.div`
  background: ${({ preview }) => (preview ? `url(${preview})` : 'transparent')};
  background-repeat: no-repeat;
  background-position: center;
`

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
`

const Row = styled.div`
  width: 100%;
  margin-bottom: 0.7rem;
  display: flex;
  justify-content: space-between;
  padding: 0 7rem;
  background-color: #f9fafb;
`

const ContentBox = styled.div`
  width: 100%;
  min-height: 68rem;
  margin: 4rem auto;
  padding: 4rem;
  padding-top: 70px;
  background: #fff;
  border: 1px solid #e7e9ee;
  border-radius: 5px;
  box-sizing: border-box;
`

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 50rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Heading = styled.div`
  flex-basis: 45%;
  height: 5rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6;
  display: flex;
  align-items: center;
`
