import React, { useState } from 'react'

import { Box } from './Layout.jsx'
import { Stat } from '../common'
import { getStat } from '../../queries/home'

function StatBoxes({ stats }) {
  const [currentStats, setStats] = useState({ ...stats })

  const updateStat = async (field, period) => {
    const data = await getStat(field, period)
    const newStats = { ...currentStats }
    newStats[field] = data

    setStats(newStats)
  }

  return (
    <>
      <Box width="19%">
        <Stat bg="#FFF5E6" title="Bookings" />
      </Box>
      <Box width="19%">
        <Stat
          bg="#E3F5FF"
          stat={currentStats.registrations}
          field="registrations"
          updateStat={updateStat}
          title="Pending"
        />
      </Box>
      <Box width="19%">
        <Stat
          bg="#E5F7EA"
          stat={currentStats.enrollments}
          field="enrollments"
          updateStat={updateStat}
          title="Enrolled"
        />
      </Box>
      <Box width="19%">
        <Stat
          bg="#eee"
          stat={currentStats.feedbacks}
          field="feedbacks"
          updateStat={updateStat}
          title="Feedbacks"
        />
      </Box>
      <Box width="19%">
        <Stat bg="#EBECFF" title="Avg time" />
      </Box>
    </>
  )
}

export { StatBoxes }
