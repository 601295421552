import styled from 'styled-components'
import { GreenButton } from '../common/Buttons'

const UploadCard = styled.div`
  width: 400px;
  margin: auto;
  padding: 7rem 2.3rem 3.3rem;
  padding-top: 70px;
  background: #fff;
  border: 1px solid #e7e9ee;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
`

const Title = styled.h1`
  line-height: 2.1rem;
  font-weight: 500;
  font-size: 1.8rem;
  font-weight: 800;
  font-family: 'Avenir Heavy', sans-serif;
`

const Camera = styled.img`
  margin: 0 auto;
  display: block;
  max-width: 17.5rem;
`

const TextInput = styled.input`
  width: 100%;
  padding: 1.47rem 1.4rem;
  margin: 1rem auto;
  background: #ffffff;
  border: 1px solid #e7e9ee;
  border-radius: 3px;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 14px;
    line-height: 16px;
    color: rgba(118, 131, 157, 0.5);
  }
`

const TextArea = styled.textarea`
  width: 100%;
  height: 15.6rem;
  padding: 1.47rem 1.4rem;
  background: #ffffff;
  border: 1px solid #e7e9ee;
  border-radius: 3px;
  font-family: 'AvenirLTStd';

  &::placeholder {
    font-size: 14px;
    line-height: 16px;
    color: rgba(118, 131, 157, 0.5);
  }
`

const AddButton = styled(GreenButton)`
  width: 100%;
  margin: 2rem auto;
`

export { Title, Camera, UploadCard, TextInput, TextArea, AddButton }
