import React from 'react';
import styled from 'styled-components';

import logoName from '../assets/images/icons/GACA.svg';
import logo from '../assets/images/icons/greenpeg_logo 1.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #f2f6f8;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled(Container)`
  width: 350px;
  height: 300px;
  border: none;
  border-radius: 1rem;
  margin: 30px;
  background-color: ${({ color }) => color};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h3`
  font-size: 1.8rem;
  margin: 50px;
  line-height: 1.2;
  color: #b5b5b5;
`;

function ChooseLogin() {
  return (
    <Wrapper>
      <Heading>Choose where to go</Heading>
      <Container>
        <Box color="#013A3A">
          <img src={logo} alt="" />
        </Box>
        <Box color="#FFF">
          <img src={logoName} alt="" />
        </Box>
      </Container>
    </Wrapper>
  );
}

export default ChooseLogin;
