import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { Row } from './Layout'
import { GreenButton, CourseImageUpload } from '../common'
import { updateBanner } from '../../queries/home'

function CourseBanner() {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const form = useRef()

  async function saveBanner(e) {
    e.preventDefault()

    if (!image) {
      toast('Please upload course image')
      return
    }

    try {
      setLoading(true)
      await updateBanner(form, image)

      setLoading(false)
      toast('New banner uploaded!')
    } catch (error) {
      console.log(error)
      toast(error.response.data.message || error.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Banner>
        <BannerTitle>Banners and more</BannerTitle>
        <BannerBody>
          <CourseImageUpload setImage={setImage} />
          <Form onSubmit={saveBanner} ref={form}>
            <Field
              required
              name="title"
              width="100%"
              type="text"
              placeholder="Course Title"
            />
            <Row>
              <Field
                required
                name="start"
                width="49%"
                type="text"
                placeholder="Start Date"
              />
              <Field
                required
                name="end"
                width="49%"
                type="text"
                placeholder="End Date"
              />
            </Row>
            <GreenButton width="100%">Publish</GreenButton>
          </Form>
        </BannerBody>
        {loading && <Overlay />}
      </Banner>
    </>
  )
}

export { CourseBanner }

const Banner = styled.div`
  background: #fff;
  width: 295px;
  margin-left: auto;
  height: 97%;
  padding-bottom: 2.4rem;
  position: relative;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
`

const BannerTitle = styled.h1`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 21px;
  color: #373737;
  padding: 3.3rem 2.4rem 2.9rem 2.4rem;
  border-bottom: 1px solid #e7e9ee;
`

const BannerBody = styled.div`
  width: 100%;
  padding-top: 2.5rem;
`

const Form = styled.form`
  padding: 2.5rem 2.4rem;
`

const Field = styled.input`
  display: block;
  outline: none;
  background: #f2f2f2;
  border: 1px solid #c4cdd5;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 1rem 1.2rem;
  margin: 2rem auto 0 auto;
  width: ${({ width }) => width};
  height: 4rem;

  &::placeholder {
    font-size: 12px;
    line-height: 21px;
    color: #aeaeae;
  }
`
