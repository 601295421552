import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTable } from 'react-table';

const tableStyle = `
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  
  td, th {
    padding: 10px 15px;
    text-align: left;
    margin: 0;

    &:first-child {
      padding-left: 30px;
    }
  }

  th {
    font-weight: 700;
    background: #f3f3f3;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  td {
    font-size: 12px;
    line-height: 1.4;
    overflow-wrap: break-word;
    vertical-align: middle;

    &:before {
      display: block;
      float: left;
      width: 0;
      content: "";
      min-height: 35px; 
    }
  }

  tr {

    &:nth-child(even) {
      background: #F9FAFB;
    }
  }
`;

const Table = ({ data, columns }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()} css={tableStyle}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{ width: column.width }}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { Table };
