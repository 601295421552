import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';

import {
  Loader,
  Overlay,
  Modal,
  ModalBody,
  ModalTitle,
  ErrorOccured,
  Table,
} from '../components/common';
import { getEnrollments } from '../queries/enrolled';

function Enrollment() {
  const { isLoading, data, isError } = useQuery('enrollments', getEnrollments, {
    retry: 1,
  });

  const columns = useMemo(() => [
    { Header: 'Full name', accessor: 'name', width: '20%' },
    { Header: 'Course title', accessor: 'course.title', width: '30%' },
    { Header: 'Email address', accessor: 'email', width: '18%' },
    { Header: 'Phone', accessor: 'phone', width: '10%' },
    {
      Header: 'Date',
      accessor: 'createdAt',
      width: '8%',
      Cell: (tableProps) => moment(tableProps.value).format('l'),
    },
  ]);

  const tableData = useMemo(() => data);

  // const [showModal, toggleModal] = useState(false)
  // const [enrollment, setEnrollment] = useState(null)

  // const openModal = (id) => {
  //   const item = data.find((e) => e.id === id)
  //   setEnrollment(item)
  //   toggleModal(true)
  // }

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  return (
    <Wrap>
      <Body>
        <Top>
          <Header>Enrolled</Header>
        </Top>
        <Table data={tableData} columns={columns} />
      </Body>
    </Wrap>
  );
}

export default Enrollment;

const Wrap = styled.div`
  background: #f2f6f8;
  width: 100%;

  padding: 2rem;
`;

const Body = styled.div`
  background: white;
  border-radius: 0.5rem;
  height: 100%;
  display: flex;
  padding: 32px;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.h1`
  z-index: 10;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Avenir Heavy', sans-serif;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
`;
