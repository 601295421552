import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'

import { ReactComponent as Plus } from '../assets/images/icons/plus.svg'
import { UsersList } from '../components/users'
import {
  GreenButton,
  Overlay,
  Modal,
  ModalBody,
  ModalTitle,
  Loader,
  ErrorOccured,
} from '../components/common'

import { getRandomLowerCase } from '../utils'
import { addUser, deleteUser, getUsers } from '../queries/user'

const Lists = styled.div``

function Users() {
  const { isLoading, data, isError } = useQuery('users', getUsers, {
    retry: 1,
  })

  console.log(data)

  const [showAddModal, toggleAddModal] = useState(false)
  const [showDelModal, toggleDelModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userToDel, setUserToDel] = useState('')
  const [userData, setUserData] = useState({
    username: '',
    email: '',
  })

  const openDelModal = (id) => {
    setUserToDel(id)
    toggleDelModal(true)
  }

  const handleAdd = async (e) => {
    e.preventDefault()

    const password = getRandomLowerCase()

    try {
      await addUser(userData, password)

      toast('Added user successfully')

      toggleAddModal(false)
      setLoading(false)
    } catch (error) {
      console.log(error.response)
      toast('Failed to add user')
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteUser(id)

      toast('Deleted user successfully')

      toggleDelModal(false)
      setLoading(true)
    } catch (error) {
      console.log(error.response)
      toast('Failed to delete user')
    }
  }

  if (isLoading || loading) {
    return <Loader />
  }

  if (isError) {
    return <ErrorOccured />
  }

  return (
    <>
      <Wrapper>
        <Body>
          <PlusDiv>
            <Plus
              style={{ fill: '#fff' }}
              onClick={() => toggleAddModal(true)}
            />
          </PlusDiv>
          <Top>
            <Header>Admin</Header>
          </Top>
          <Lists>
            <UsersList users={data} toggleDelModal={openDelModal} />
          </Lists>
        </Body>
      </Wrapper>
      {showAddModal && (
        <Overlay>
          <Modal>
            <ModalTitle>
              <h3>Add New User</h3>
              <h3
                onClick={() => toggleAddModal(false)}
                style={{ cursor: 'pointer' }}
              >
                X
              </h3>
            </ModalTitle>
            <ModalBody onSubmit={handleAdd}>
              <Input
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
                value={userData.email}
                type="email"
                placeholder="Email"
              />
              <Input
                onChange={(e) =>
                  setUserData({ ...userData, username: e.target.value })
                }
                value={userData.username}
                placeholder="Username"
              />
              <Submit style={{ marginLeft: 'auto' }}>Invite User</Submit>
            </ModalBody>
          </Modal>
        </Overlay>
      )}
      {showDelModal && (
        <Overlay>
          <Modal>
            <ModalTitle>
              <h3>Are you sure you want to delete?</h3>
              <h3
                onClick={() => toggleDelModal(false)}
                style={{ cursor: 'pointer' }}
              >
                X
              </h3>
            </ModalTitle>
            <ModalBody>
              <Actions>
                <Cancel onClick={() => toggleDelModal(false)}>No</Cancel>
                <Okay onClick={() => handleDelete(userToDel)}>Yes</Okay>
              </Actions>
            </ModalBody>
          </Modal>
        </Overlay>
      )}
    </>
  )
}

export default Users

const Wrapper = styled.div`
  background: #f2f6f8;
  width: 100%;
  padding: 2rem;
`

const Body = styled.div`
  background: white;
  border-radius: 0.5rem;
  min-height: calc(100vh - 150px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Header = styled.h1`
  z-index: 10;
  font-weight: 500;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Avenir Heavy';
  margin-bottom: 6.5rem;
`

const PlusDiv = styled.div`
  z-index: 10;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin-left: auto;
  background: #5aba5e;
  border-radius: 0.5rem;
  color: white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
  width: 80%;
`

const Actions = styled.div`
  display: flex;
`

const Submit = styled(GreenButton)`
  margin-left: auto;
`

const Cancel = styled(GreenButton)`
  background: red;
`

const Okay = styled(GreenButton)``

const Input = styled.input`
  background: #f2f2f2;
  border: 1px solid #e3e3e3;
  padding: 1.8rem;
  font-family: 'AvenirLTStd';
  width: 100%;
  margin-bottom: 2rem;

  &::placeholder {
    font-size: 16px;
    color: #aeaeae;
  }
`
