import React from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'

import {
  StatBoxes,
  TrendingCard,
  CourseCount,
  CourseBanner,
  Registrations,
  Row,
  Box,
  Welcome,
} from '../components/home'
import { Loader, ErrorOccured } from '../components/common'

import { getHomeData } from '../queries/home'
import { useContext } from 'react'
import { AuthContext } from '../contexts/auth'

function Home() {
  const {authState} = useContext(AuthContext)

  const { isLoading, data, isError } = useQuery(['home', authState.id], getHomeData, {
    retry: 1,
  })

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <ErrorOccured />
  }

  return (
    <Wrapper>
      <Row>
        <Box width="70%">
          <Row>
            <Welcome />
          </Row>
          <Row>
            <StatBoxes stats={data.stats} />
          </Row>
          <Row>
            <Box width="32.3%">
              <TrendingCard
                title="Top Selling Courses"
                items={data.topCourses}
              />
            </Box>
            <Box
              width="32.3%"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <CourseCount count={data.count} />
            </Box>
            <Box width="32.3%">
              <TrendingCard />
            </Box>
          </Row>
        </Box>
        <Box width="30%">
          <CourseBanner />
        </Box>
      </Row>
      <Row>
        <Registrations registrations={data.registrations} />
      </Row>
    </Wrapper>
  )
}

export default Home

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 5rem 3rem;
`
