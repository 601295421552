import React from 'react'
import styled from 'styled-components'

import caret from '../../assets/images/icons/caret.svg'
import arrow from '../../assets/images/icons/arrow.svg'

function Stat({ title, bg, stat, field, updateStat }) {
  const [showOptions, setShowOptions] = React.useState(false)
  const [period, setPeriod] = React.useState('all')

  React.useEffect(() => {
    if (updateStat) updateStat(field, period)
  }, [period, field])

  return (
    <Box>
      <Top>
        <Title>{title}</Title>
        <Period onClick={() => setShowOptions(!showOptions)}>
          {period === 'all' ? 'All time' : `This ${period}`}
          <img src={caret} alt="caret icon" />
        </Period>
      </Top>
      <Number bg={bg}>
        <p>{stat || 0}</p>
      </Number>
      <Bottom>
        <img src={arrow} alt="arrow icon" />
        <p>
          <Percentage>38.5%</Percentage> from last month
        </p>
      </Bottom>

      {showOptions && (
        <Options onClick={() => setShowOptions(!showOptions)}>
          <p onClick={() => setPeriod('all')}>All time</p>
          <p onClick={() => setPeriod('week')}>This Week</p>
          <p onClick={() => setPeriod('month')}>This Month</p>
        </Options>
      )}
    </Box>
  )
}

export { Stat }

const Box = styled.div`
  width: 157px;
  height: 147px;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  background: #fff;
  position: relative;
`

const Title = styled.h3`
  font-size: 1.2rem;
  color: #76839d;
  font-family: 'Avenir Heavy', sans-serif;
`

const Period = styled.div`
  font-size: 10px;
  color: #aeaeae;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    padding-left: 0.4rem;
  }
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bg }) => bg};
  border-radius: 5px;
  height: 45px;
  width: 45px;

  p {
    font-size: 17px;
    font-weight: bold;
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
  p {
    font-size: 10px;
  }
`

const Percentage = styled.span`
  color: ${({ theme: { gaca } }) => gaca.gacaGreen};
  margin-right: 0.5rem;
`

const Options = styled.div`
  position: absolute;
  background: #f9fafb;
  border: 0.4px solid #e7e9ee;
  box-shadow: 0px 1px 10px rgba(123, 123, 123, 0.1);
  top: 3rem;
  right: 1.2rem;
  padding: 0.8rem;

  p {
    font-size: 9px;
    color: #76839d;
    line-height: 13px;
    cursor: pointer;
  }
`
