import instance from './axios'

export const getFeedbacks = async () => {
  const { data } = await instance.get(
    'https://greenpeg.herokuapp.com/feedbacks'
  )

  return data
}

export const deleteFeedback = async (id) => {
  instance.delete(`/feedbacks/${id}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const addFeedback = async (form, file) => {
  let formData = new FormData(form.current)
  let data = {}
  formData.forEach((value, key) => (data[key] = value))

  const feedback = await instance.post('/feedbacks', data)

  if (file) {
    let imageData = new FormData()
    imageData.append('files', file)
    imageData.append('ref', 'feedback')
    imageData.append('refId', feedback.data.id)
    imageData.append('field', 'image')

    // Upload Image
    await instance.post('/upload/', imageData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
