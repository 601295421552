import axios from 'axios'
import { history } from '../App';

const { token } = JSON.parse(localStorage.getItem('auth')) || { token: null }

const instance = axios.create({
  baseURL: 'https://greenpeg.herokuapp.com',
})

instance.defaults.headers.common['Authorization'] = `Bearer ${token}`

instance.interceptors.response.use((response) => response, (error) => {
  
  if(error?.status === 401) {
    console.log('error')
    localStorage.setItem('auth', null)
    history.push('/login')
    return
  }

  throw error;
});

export default instance;