import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

import upload from '../../assets/images/upload.svg'
import mail from '../../assets/images/icons/mail.svg'
import linkedin from '../../assets/images/icons/linkedin.svg'

import {
  Camera,
  UploadCard,
  TextInput,
  TextArea,
  AddButton,
} from '../common/Upload'

function AddTutor({ saveTutor, blobImage, setblobImage, setFile, form }) {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0])
      setblobImage(URL.createObjectURL(acceptedFiles[0]))
    },
  })

  return (
    <UploadCard as="form" ref={form} onSubmit={saveTutor}>
      <Header>Add Tutor</Header>
      <ImageBox preview={blobImage} {...getRootProps()}>
        <input {...getInputProps()} />
        <Camera src={upload} alt="" />
      </ImageBox>
      <TextInput name="name" placeholder="Enter Name" />
      <TextArea name="about" placeholder="Short Description" />
      <Contact>
        <div>
          <img src={mail} alt="" />
          <input name="email" type="email" />
        </div>
        <div>
          <img src={linkedin} alt="" />
          <input name="linkedin" />
        </div>
      </Contact>
      <AddButton>Add</AddButton>
    </UploadCard>
  )
}

export { AddTutor }

const Header = styled.h1`
  font-size: 1.8rem;
  margin: 0 2rem 5rem;
  font-family: 'Avenir Heavy';
`

const ImageBox = styled.div`
  background: ${({ preview }) => (preview ? `url(${preview})` : 'transparent')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-bottom: 6rem;
`

const Contact = styled.div`
  margin: 1rem 0;

  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    width: 49%;
    background: #ffffff;
    border: 1px solid #e7e9ee;
    border-radius: 3px;
    padding: 1.5rem;
  }

  input {
    margin-left: 0.5rem;
    width: 100%;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    box-sizing: content-box;
  }
`
