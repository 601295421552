import instance from './axios';
import axios from 'axios';
import { toast } from 'react-toastify';

import {
  calculateTopCourses,
  getFirstDayOfTheMonth,
  getFirstDayOfTheWeek,
} from '../utils';

export const getHomeData = async (_, id) => {
  const [
    registrations,
    pending,
    registrationCount,
    enrolledCount,
    feedbackCount,
    byUser,
    total,
  ] = await axios.all([
    instance.get('/registrations?status=PENDING&_limit=10'),
    instance.get(
      '/registrations?status_ne=APPROVED&_sort=createdAt:DESC&_limit=10'
    ),
    instance.get('/registrations/count'),
    instance.get('/enrollments/count'),
    instance.get('/feedbacks/count'),
    instance.get(`/courses/count?uploadedBy=${id}`),
    instance.get('/courses/count'),
  ]);

  return {
    topCourses: calculateTopCourses(registrations.data),
    registrations: pending.data,
    stats: {
      registrations: registrationCount.data,
      enrollments: enrolledCount.data,
      feedbacks: feedbackCount.data,
    },
    count: {
      total,
      byUser,
    },
  };
};

export const approve = async (id, email) => {
  await instance.post('/registrations/approve', {
    id,
    email,
  });

  toast.success('Approved successfully');
};

export const getStat = async (field, period) => {
  if (period === 'week') {
    const { data } = await instance.get(
      `/${field}/count?createdAt_gt=${getFirstDayOfTheWeek()}`
    );

    return data;
  } else if (period === 'month') {
    const { data } = await instance.get(
      `/${field}/count?createdAt_gt=${getFirstDayOfTheMonth()}`
    );

    return data;
  } else {
    const { data } = await instance.get(`/${field}/count`);

    return data;
  }
};

export const updateBanner = async (form, image) => {
  let formData = new FormData(form.current);

  let data = {};
  formData.forEach((value, key) => (data[key] = value));

  const banner = await instance.put('/course-banner', data);

  let imageData = new FormData();
  imageData.append('files', image);
  imageData.append('ref', 'course-banner');
  imageData.append('refId', banner.data.id);
  imageData.append('field', 'image');

  // Upload Image
  await instance.post('/upload', imageData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
