import React from 'react'
import moment from 'moment'

import { Wrapper, Container, Box } from './CourseRegListUI'
import styled from 'styled-components'

const NewBox = styled(Box)`
  justify-content: flex-start;
`

const truncateString = (str) => {
  if (!str) return ''
  if (str.length < 55) return str
  return str.slice(0, 47) + '...'
}

function capitalize(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

function CourseRegPlus({ id, registration, openModal }) {
  return (
    <Wrapper>
      <Container>
        <NewBox width="12%">{capitalize(registration.name)}</NewBox>
        <NewBox width="35%">{truncateString(registration.course.title)}</NewBox>
        <NewBox width="18%">{registration.email}</NewBox>
        <NewBox width="8%">{registration.phone}</NewBox>
        <NewBox width="8%">
          {' '}
          {moment(registration.createdAt).format('l')}
        </NewBox>
        <NewBox width="auto">
          <Button onClick={() => openModal(id)}>View Details</Button>
        </NewBox>
      </Container>
    </Wrapper>
  )
}
export { CourseRegPlus }

const Button = styled.button`
  width: 100%;
  height: 3rem;
  /* padding: 1rem; */
  color: #fff;
  border: none;
  border-radius: 2px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  background: ${({ theme: { gaca } }) => gaca.gacaBlue};
`
