import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { ReactComponent as Plus } from '../../assets/images/icons/plus.svg'

function FeedbackContent({ feedback, deleteFeedback }) {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <>
      <Row>
        <Col>
          <SmallCircle color="#FEDBA8"></SmallCircle>
          <Details>
            {' '}
            <Name>{feedback.name}</Name>
          </Details>
        </Col>
        <Col>
          <Text>{moment(feedback.createdAt).calendar()}</Text>
        </Col>
        <PlusBox>
          <Plus onClick={() => setShowDetails(!showDetails)} />
        </PlusBox>
      </Row>
      {showDetails && (
        <FeedbackBox>
          <p>"{feedback.comment}"</p>
          <div>
            <DeleteFeedback onClick={() => deleteFeedback(feedback.id)}>
              Delete
            </DeleteFeedback>
          </div>
        </FeedbackBox>
      )}
    </>
  )
}

export { FeedbackContent }

const Row = styled.div`
  width: 100%;
  margin-bottom: 0.7rem;
  display: flex;
  justify-content: space-between;
  padding: 0 7rem;
  background-color: #f9fafb;
`

const DeleteFeedback = styled.button`
  background: #ff0040;
  border-radius: 4px;
  padding: 1rem 5rem;
  color: white;
  margin-right: 3rem;
  border: none;
  outline: none;
`

const FeedbackBox = styled.div`
  width: 100%;
  padding: 7rem;
  display: flex;
  justify-content: space-between;

  p {
    font-size: 2rem;
    line-height: 2.4rem;
    color: #76839d;
    max-width: 450px;
  }
`

const SmallCircle = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border: 1px solid ${({ color }) => color};
  margin-right: 0.5rem;
  background-color: ${({ color }) => color};
`

const Details = styled.div`
  width: 80%;
  margin: 0.5rem;
`

const Col = styled.div`
  flex-basis: 45%;
  height: 8rem;
  display: flex;
  align-items: center;
`

const PlusBox = styled(Col)`
  flex-basis: 10%;
  display: flex;
  justify-content: flex-end;
  svg {
    fill: ${({
      theme: {
        gaca: { silverGrey },
      },
    }) => silverGrey};
  }
`

const Name = styled.h2`
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.5;
`

const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1;
  color: ${({
    theme: {
      gaca: { subtleGrey },
    },
  }) => subtleGrey};
`
