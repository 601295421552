import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import bluelinkedin from '../../assets/images/icons/bluelinkedin.svg'
import option from '../../assets/images/icons/option.svg'

import { CardBox, Info, Title } from '../common/CardUI'

import { deleteTutor } from '../../queries/tutors'

function TutorCard({ tutor, setTutor, setTutors }) {
  const [showOption, setShowOption] = useState(false)

  const deleteFunction = async (id) => {
    let confirmDelete = window.confirm('Do you want to delete tutor')
    if (confirmDelete) {
      await deleteTutor(id)
      toast('Deleted')
    }
  }

  return (
    <CardWrapper style={{ width: '350px' }}>
      <Options>
        <img onClick={() => setShowOption(!showOption)} src={option} alt="" />
        <div
          onClick={() => setShowOption(false)}
          style={{ display: showOption ? 'block' : 'none' }}
        >
          <p onClick={() => setTutor(tutor)} style={{ color: '#5ABA5E' }}>
            Edit tutor{' '}
          </p>
          <p onClick={() => deleteFunction(tutor.id)}>Delete tutor</p>
        </div>
      </Options>
      <img src={tutor?.image?.url} alt="" />
      <Info>
        <Name>{tutor.name}</Name>
      </Info>
      <Title style={{ width: '100%' }}>{tutor.about}</Title>
      <Social>
        <img src={bluelinkedin} alt="" />
      </Social>
    </CardWrapper>
  )
}

export { TutorCard }

const Options = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  cursor: pointer;
  background: #fff !important;

  div {
    padding: 1rem;
    border: 0.5px solid rgba(232, 232, 232, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(51, 51, 51, 0.1);
    border-radius: 2px;
    position: absolute;
    top: 35px;
    z-index: 100;
    width: 95px;
    right: -10px;
    background: #fff;
  }

  p {
    font-size: 1rem;
    line-height: 1.8;
    cursor: pointer;
  }

  img {
    cursor: pointer;
    padding: 0 1rem;
  }
`

const CardWrapper = styled(CardBox)`
  padding: 2rem;
  border-radius: 10px;

  & > img {
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    object-fit: cover;
    height: 320px;

    object-position: center;
  }
`

const Name = styled.p`
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1;
`

const Social = styled.span`
  img {
    margin-right: 1rem;
  }
`
