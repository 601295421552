import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Plus } from '../assets/images/icons/plus.svg'
import { GreenButton } from '../components/common/Buttons'

function EmptyFeedbacks() {
  return (
    <Wrapper>
      <Container>
        <SidePlus>
          <Plus></Plus>
        </SidePlus>
        <Box>
          <Title>Feedbacks</Title>
        </Box>
        <ContentBox>
          <Title style={{ fontWeight: 'bold' }}>Nothing Here</Title>
          <Paragraph>
            You have not added any course yet, click the button below to add a
            course
          </Paragraph>
          <GreenButton>Add Course</GreenButton>
        </ContentBox>
      </Container>
    </Wrapper>
  )
}

export default EmptyFeedbacks

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;
  min-height: 100vh;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #e7e9ee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const SidePlus = styled.span`
  width: 30px;
  height: 30px;
  border-top-right-radius: 5px;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({
    theme: {
      gaca: { gacaGreen },
    },
  }) => gacaGreen};
  color: #fff;
  svg {
    fill: #fff;
  }
`

const Title = styled.h1`
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 500;
`

const Box = styled.div`
  width: calc(100% - 4rem);
  margin: 3rem;
`

const ContentBox = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Paragraph = styled.p`
  width: 24rem;
  font-size: 1.4rem;
  font-weight: normal;
  text-align: center;
  line-height: 1.2;
  margin: 1rem 0 2rem;
  color: ${({
    theme: {
      gaca: { silverGrey },
    },
  }) => silverGrey};
`
