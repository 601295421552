import React from 'react'
import styled from 'styled-components'
import { GreenButton } from '../components/common'
import { ReactComponent as Plus } from '../assets/images/icons/plus.svg'
import { Link } from 'react-router-dom'

function EmptyCourses() {
  return (
    <Wrapper>
      <Body>
        <PlusDiv to="/courses/add">
          <Plus style={{ fill: '#fff' }} />
        </PlusDiv>
        <Header>Training Courses</Header>
        <EmptyBox>
          <div>
            <h1>Nothing here</h1>
            <p>
              You have not added any course yet, click the button below to add a
              course
            </p>
            <AddCourseButton as={Link} to="/courses/add">
              Add New Course
            </AddCourseButton>
          </div>
        </EmptyBox>
      </Body>
    </Wrapper>
  )
}

export default EmptyCourses

const Wrapper = styled.div`
  background: #f2f6f8;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
`

const Body = styled.div`
  background: white;
  border-radius: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.h1`
  z-index: 10;
  font-weight: 500;
  font-size: 2rem;
  margin-left: 3.5rem;
`

const PlusDiv = styled(Link)`
  z-index: 10;
  width: 40px;
  height: 40px;
  margin-left: auto;
  background: #5aba5e;
  border-radius: 0.5rem;

  color: white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyBox = styled.div`
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  margin-top: -60px;
  div {
    width: 236px;
    text-align: center;
  }
  h1 {
    font-size: 2.4rem;
    font-weight: bold;
  }
  p {
    color: #76839d;
    font-size: 1.4rem;
    margin-top: 1.7rem;
    line-height: 2rem;
  }
`

const AddCourseButton = styled(GreenButton)`
  width: 100%;
  margin-top: 2.2rem;
  display: inline-block;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`
