import React, { useContext } from 'react'
import styled from 'styled-components'

import file from '../../assets/images/icons/file-alt.svg'
import plus from '../../assets/images/icons/plus-alt.svg'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'

function CourseCount({ count }) {
  const {authState} = useContext(AuthContext)

  return (
    <>
      <CoursesUploaded>
        <Row>
          <img src={file} alt="file icon" />
          <p>Published {count.byUser.data} &nbsp; Drafts 0</p>
        </Row>
        <Row>
          <h3>{count.byUser.data}</h3>
        </Row>
        <Row>
          <p>Courses uploaded by {authState.username}</p>
        </Row>
      </CoursesUploaded>
      <TotalCourses>
        <Row>
          <p>Total Courses</p>
          <Link to="/courses/add">
            <img src={plus} alt="plus icon" />
          </Link>
        </Row>
        <Row>
          <h3>{count.total.data}</h3>
        </Row>
        <Row>
          <p>
            <Link to="/courses">View Courses</Link>
          </p>
        </Row>
      </TotalCourses>
    </>
  )
}

export { CourseCount }

const Card = styled.div`
  width: 275px;
  height: 150px;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CoursesUploaded = styled(Card)`
  background: linear-gradient(263.69deg, #91ebff -20.9%, #20b1ef 45.85%);
  box-shadow: inset 5px 5px 25px rgba(26, 140, 210, 0.7);
  margin-bottom: 2rem;
`

const TotalCourses = styled(Card)`
  background: linear-gradient(180deg, #ffb955 4%, #ff9a02 80.67%);
  box-shadow: inset 5px 5px 25px rgba(244, 138, 40, 0.7);
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
  font-family: 'Avenir Heavy', sans-serif;

  p {
    font-size: 1.2rem;
    line-height: 1.6rem;

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  h3 {
    font-weight: 900;
    font-size: 5rem;
    line-height: 5.9rem;
  }
`
