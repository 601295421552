import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { GreenButton } from '../components/common'

import logo from '../assets/images/logo.svg'
import gplogo from '../assets/images/gplogo.png'

import { AuthContext, LOGIN } from '../contexts/auth'

const Title = styled.p`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 3rem;
  color: ${({ theme: { gaca } }) => gaca.subtleGrey};
`

const LoginBox = styled.form`
  width: 552px;
  height: 379px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 7rem;
  padding-top: 4.5rem;
`

const Input = styled.input`
  width: 404.84px;
  height: 47.73px;
  border: 1px solid rgba(118, 131, 157, 0.2);
  border-radius: 3px;
  margin-bottom: 2.3rem;
  font-size: 1.4rem;
  padding: 1.6rem 2rem;
`
const Logo = styled.img`
  display: block;
  margin: 0 auto 2.2rem;
`

// const Forgot = styled.a`
//   color: ${({ theme: { gaca } }) => gaca.gacaGreen};
//   font-size: 1.4rem;
//   border: 0;
// `

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background: #f2f6f8;
`

const Switch = styled.div`
  width: 110px;
  height: 62px;
  background: #013a3a;
  border-radius: 999px 0 0 999px;
  padding: 1.25rem 1.7rem;
  position: absolute;
  top: 7.1rem;
  right: 0;
`

const Copy = styled.p`
  position: absolute;
  font-size: 1.3rem;
  color: #c4c4c4;
  bottom: 5rem;
`

const GPLogo = styled.img`
cursor: pointer;`

function Login() {
  const { dispatch } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  let history = useHistory()

  async function login(e) {
    e.preventDefault()

    try {
      setLoading(true)

      const res = await axios.post(
        'https://greenpeg.herokuapp.com/auth/local',
        {
          identifier: email,
          password,
        }
      )

      // console.log(res.data)

      const { user, jwt } = res.data

      dispatch({ type: LOGIN, payload: { ...user, token: jwt } })

      history.push('/')
      window.location.reload()
      // toast.error(`Welcome, ${user.username}`)
    } catch (error) {
      // toast.error(error.response?.data?.message || error.message)
      setLoading(false)
      console.log(error.response || error.message)
    }
  }

  return (
    <Section>
      <Switch>
        <GPLogo src={gplogo} alt="" onClick={() => window.open('https://admin.greenpegltd.com/login', '_blank').focus()} />
      </Switch>
      <Title>Login</Title>
      <LoginBox onSubmit={login}>
        <Logo width="132px" src={logo} alt="" />
        <Input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email address"
        />
        <Input
          type="password"
          name="password"
          alue={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <Bottom>
          <GreenButton>{loading ? 'Loading...' : 'Login'}</GreenButton>
          {/* <Forgot href="#">Forgot Password?</Forgot> */}
        </Bottom>
      </LoginBox>
      <Copy>©2020 Greenpeg Academy</Copy>
    </Section>
  )
}

export default Login
