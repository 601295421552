import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import uploadImg from '../../assets/images/icons/image.svg'
import { Button } from './Buttons'

const DashedBox = styled.div`
  border: 2px dashed #dfe3e8;
  border-radius: 2px;
  padding: 2.5rem;
  margin: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ preview }) => (preview ? `url(${preview})` : 'transparent')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  cursor: pointer;

  img {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  button {
    margin: 0;
    padding: 1rem 3rem;
  }
`

const WhiteButton = styled(Button)`
  width: 100%;
  color: black;
  border: 1px solid #c4cdd5;
  background: #fff;
  border-radius: 3px;
  margin: 2rem 2rem 0;
  width: auto;
`

function CourseImageUpload({ image, setImage }) {
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (file) URL.revokeObjectURL(file.preview)
  }, [file])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]

      console.log(file)

      const reader = new FileReader()

      reader.addEventListener('load', function () {
        const fileObj = Object.assign(file, {
          preview: this.result,
        })

        setFile(fileObj)
        setImage(fileObj)
      })

      reader.readAsDataURL(file)
    },
  })

  return (
    <>
      <DashedBox
        preview={file ? file.preview : image || ''}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <img src={uploadImg} alt="" />
        <input {...getInputProps()} />

        <WhiteButton type="button">Upload Banner</WhiteButton>
      </DashedBox>
    </>
  )
}

export { CourseImageUpload }
