import instance from 'axios'
let base = 'https://shielded-sea-55583.herokuapp.com/api'
// let base = 'http://localhost:5000/api'

export const getBlog = async (key, id) => {
  const { data } = await instance.get(`${base}/blog/gaca/all`)

  return data.data
}


export const getSingleBlog = async (key, id) => {
    const { data } = await instance.get(`/blog/gaca/${id}`)
  
    return data
}

export const deleteBlog = (id) => {
  instance.delete(`${base}/blog/gaca/${id}`)
}

export const getBlogCategories = async () => {
  const { data } = await instance.get(`${base}/blog/gaca/category`)

  return data.data
}


export const updateBlog = async (newData) => {

    // let data = new FormData();
    // data.append('photo', newData.image);
    // data.append('tags', newData.tags);
    // data.append('title', newData.title);
    // data.append('category', newData.category);
    // data.append('content', newData.content);
    // data.append('author', newData.author);

    // let config = {
    //   method: 'put',
    //   url: `${base}/blog/gaca/${newData.id}`,
    //   headers: {},
    //   data : data
    // };

    // instance(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

    // console.log(newData, 'newData')

    
    var data = new FormData();
    data.append('photo', newData.image);
    data.append('tags', newData.tags);
    data.append('title', newData.title);
    data.append('category', newData.category);
    data.append('content', newData.content);
    data.append('author', newData.author);

    var config = {
      method: 'put',
      url: `${base}/blog/gaca/${newData.id}`,
      headers: {},
      data : data
    };

    instance(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });

}


export const addBlog = async (newData) => {

    var data = new FormData();
    data.append('photo', newData.image);
    data.append('tags', newData.tags);
    data.append('title', newData.title);
    data.append('content', newData.content);
    data.append('author', newData.author);
    data.append('category', newData.category);

    var config = {
        method: 'post',
        url: `${base}/blog/gaca/`,
        headers: {},
        data : data
    };

    instance(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
        })
    .catch(function (error) {
        console.log(error);
    });
}




// export const getCourses = async (key, visibility, page = 1) => {
//   const skip = page === 1 ? 0 : (page * 12) / 2

//   const URL = visibility
//     ? `/courses?_start=${skip}&_limit=12&visibility=${visibility}`
//     : `/courses?_start=${skip}&_limit=12`

//   const { data } = await instance.get(URL)

//   return data
// }

// export const addBlog = async (form, image) => {
//   let formData = new FormData(form.current)

//   let data = {}
//   formData.forEach((value, key) => (data[key] = value))

//   const course = await instance.post('/courses', data)

//   let imageData = new FormData()
//   imageData.append('files', image)
//   imageData.append('ref', 'course')
//   imageData.append('refId', course.data.id)
//   imageData.append('field', 'image')

//   // Upload Image
//   await instance.post('/upload', imageData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
// }

// export const updateCourse = async (form, image, id) => {
//   let formData = new FormData(form.current)

//   let data = {}
//   formData.forEach((value, key) => (data[key] = value))

//   const course = await instance.put(`/courses/${id}`, data)

//   if (image) {
//     let imageData = new FormData()
//     imageData.append('files', image)
//     imageData.append('ref', 'course')
//     imageData.append('refId', course.data.id)
//     imageData.append('field', 'image')

//     await instance.post('/upload', imageData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     })
//   }
// }
