function getOccurrence(array, value) {
  return array.filter((v) => v.course.id === value).length
}

export function calculateTopCourses(registrations = []) {
  const topCourses = []

  registrations.forEach((reg) => {
    const count = getOccurrence(registrations, reg.course.id)
    topCourses.push({
      name: reg.course.title.split(':')[0],
      count,
    })
  })

  return topCourses
}

export function truncateString(str) {
  if (str.length < 55) return str
  return str.slice(0, 40) + '...'
}

export function capitalize(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export function getFirstDayOfTheWeek() {
  const curr = new Date() // get current date
  const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week

  const firstday = new Date(curr.setDate(first))

  return JSON.stringify(firstday).replace(/['"]+/g, '')
}

export function getFirstDayOfTheMonth() {
  const date = new Date()
  const firstday = new Date(date.getFullYear(), date.getMonth(), 1)

  return JSON.stringify(firstday).replace(/['"]+/g, '')
}

export function getRandomLowerCase() {
  let p = ''
  for (let index = 0; index < 9; index++) {
    p += String.fromCharCode(Math.floor(Math.random() * 26) + 97)
  }

  return p
}
