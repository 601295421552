import instance from './axios';

export const getEnrollments = async () => {
  const { data } = await instance.get(
    '/registrations?status=APPROVED&_sort=createdAt:DESC'
  );

  return data;
};

export const getRegistrations = async () => {
  const { data } = await instance.get(
    '/registrations?status_ne=APPROVED&_sort=createdAt:DESC'
  );

  return data;
};
