import instance from './axios'

export const getCourses = async (key, visibility, page = 1) => {
  const skip = page === 1 ? 0 : (page * 12) / 2

  const URL = visibility
    ? `/courses?_start=${skip}&_limit=12&visibility=${visibility}`
    : `/courses?_start=${skip}&_limit=12`

  const { data } = await instance.get(URL)

  return data
}

export const getCourse = async (key, id) => {
  const { data } = await instance.get(`/courses/${id}`)

  return data
}

export const deleteCourse = (id) => {
  instance.delete(`/courses/${id}`)
}

export const getCategories = async () => {
  const { data } = await instance.get('/categories')

  return data
}

export const addCourse = async (form, image) => {
  // let formData = new FormData(form.current)

  // console.log(form, 'fff')
  // console.log(image, 'mmm')

  // let data = {}
  // formData.forEach((value, key) => (data[key] = value))

  const course = await instance.post('/courses', form)

  let imageData = new FormData()
  imageData.append('files', image)
  imageData.append('ref', 'course')
  imageData.append('refId', course.data.id)
  imageData.append('field', 'image')

  // Upload Image
  await instance.post('/upload', imageData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const updateCourse = async (form, image, id) => {
  let formData = new FormData(form.current)

  let data = {}
  formData.forEach((value, key) => (data[key] = value))

  const course = await instance.put(`/courses/${id}`, data)

  if (image) {
    let imageData = new FormData()
    imageData.append('files', image)
    imageData.append('ref', 'course')
    imageData.append('refId', course.data.id)
    imageData.append('field', 'image')

    await instance.post('/upload', imageData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
