import React from 'react';
import styled from 'styled-components';

import hamburger from '../../assets/images/icons/hamburger.svg';
import home from '../../assets/images/icons/home.svg';
import course from '../../assets/images/icons/course.svg';
import person from '../../assets/images/icons/person-green.svg';
import comment from '../../assets/images/icons/comment.svg';
import tutor from '../../assets/images/icons/tutor.svg';
import user from '../../assets/images/icons/user.svg';

const Box = styled.div`
  width: 56px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  color: ${({ theme: { gaca } }) => gaca.gacaGreen};
  background: #fff;
  margin: 0 1rem;

  img {
    margin: 1.8rem 0;
    max-width: 1.4rem;
  }
`;
function SmallSidebar() {
  return (
    <Box>
      <img src={hamburger} alt="hamburger icon" />
      <img src={home} alt="home icon" />
      <img src={course} alt="course icon" />
      <img src={person} alt="user icon" />
      <img src={comment} alt="comment icon" />
      <img src={tutor} alt="user icon" />
      <img src={user} alt="user icon" />
    </Box>
  );
}

export { SmallSidebar };
