import React, { useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as Plus } from '../assets/images/icons/plus.svg'
import { useHistory } from 'react-router-dom'

import {
  Loader,
  Overlay,
  Modal,
  ModalBody,
  ModalTitle,
  Wrapper,
  Container,
  Box,
  CourseRegPlus,
  ErrorOccured,
  BlogTable
} from '../components/common'
import { getBlog, deleteBlog } from '../queries/blog'
import { getEnrollments } from '../queries/enrolled'
import { toast } from 'react-toastify'

function BlogsList() {
  // const { isLoading, data, isError } = useQuery('enrollments', getEnrollments, {
  //   retry: 1,
  // })
  const history = useHistory()

    const { isLoading, data: blogData, isError } = useQuery('blog', getBlog, {
        retry: 1,
    })
    

    console.log(blogData, 'getBlog')

  const [showModal, toggleModal] = useState(false)
  const [showEDModal, toggleEDModal] = useState(null)
  const [enrollment, setEnrollment] = useState(null)
  const [edItem, setEdItem] = useState(null)
  const [loading, setloading] = useState(false)

  const openModal = (id) => {
    const item = blogData.find((e) => e._id === id)
    setEnrollment(item)
    toggleModal(true)
  }

//   console.log(edItem, 'edItem')

  const openDEModal = (index) => {
    toggleEDModal(index)
    if(index === showEDModal){
        toggleEDModal(null)
    }
  }

  const handleBlogDelete = async (id) => {
    await deleteBlog(id)
    toast('Blog deleted successfully')
  }

  if (isLoading || loading || blogData === 'undefined') {
    return <Loader />
  }

  if (isError) {
    return <ErrorOccured />
  }

  return (
    <Wrap>
      <Body>
        <PlusDiv to="/blog/add">
          <Plus style={{ fill: '#fff' }} />
        </PlusDiv>
        <Top>
          <Header>Blogs</Header>
        </Top>
        <Wrapper>
          <Container style={{ background: '#F3F3F3' }}>
            <NewBox header={true} width="12%">
              Title
            </NewBox>
            <NewBox header={true} width="25%">
              Content
            </NewBox>
            {/* <NewBox header={true} width="18%">
              Email Address
            </NewBox> */}
            <NewBox header={true} width="8%">
              Image
            </NewBox>
            <NewBox header={true} width="8%">
              Date
            </NewBox>
            <NewBox
              header={true}
            //   style={{ justifyContent: 'center' }}
              width="5%"
            ></NewBox>
            <NewBox
              header={true}
            //   style={{ justifyContent: 'center' }}
              width="5%"
            ></NewBox>
          </Container>
        </Wrapper>
        {blogData?.map((reg, index) => (
          <>
            <BlogTable openModal={openModal} showEDModal={showEDModal} openDEModal={openDEModal} key={reg.id} index={index} reg={reg} id={reg._id} handleBlogDelete={handleBlogDelete} />
            {showModal && (
              <Overlay>
                <Modal>
                  <ModalTitle>
                    <h3>Blog Details</h3>
                    <h3
                      onClick={() => toggleModal(false)}
                      style={{ cursor: 'pointer' }}
                    >
                      X
                    </h3>
                  </ModalTitle>
                  <ModalBody h="500px">
                    <Item>
                      <Title>Title: </Title>
                      <p>{enrollment.title}</p>
                    </Item>
                    <Item>
                      <Title>Content: </Title>
                      {enrollment.content}
                    </Item>
                    <Item>
                      <Title>Photo: </Title>
                      <img src={enrollment.photo} width="40%" height="30%" />
                    </Item>
                    <Item>
                      <Title>Tags: </Title>
                      <p>{enrollment.tags}</p>
                    </Item>
                    {/* <Item>
                      <Title>Phone: </Title>
                      <p>{enrollment.phone}</p>
                    </Item>
                    <Item>
                      <Title>Degree: </Title>
                      <p>{enrollment.degree}</p>
                    </Item>
                    <Item>
                      <Title>Years of Experience: </Title>
                      <p>{enrollment.yearsOfExerience}</p>
                    </Item>
                    <Item>
                      <Title>Company: </Title>
                      <p>{enrollment.company}</p>
                    </Item>
                    <Item>
                      <Title>Company Address: </Title>
                      <p>{enrollment.companyAddress}</p>
                    </Item>
                    <Item>
                      <Title>Sponsor Name: </Title>
                      <p>{enrollment.sponsorName}</p>
                    </Item>
                    <Item>
                      <Title>Sponsor Address: </Title>
                      {enrollment.sponsorAddress}
                    </Item>
                    <Item>
                      <Title>Company Phone: </Title>
                      <p>{enrollment.companyPhone}</p>
                    </Item>
                    <Item>
                      <Title>Job Status: </Title>
                      <p>{enrollment.currentJobStatus}</p>
                    </Item> */}
                  </ModalBody>
                </Modal>
              </Overlay>
            )}
          </>
        ))}
      </Body>
    </Wrap>
  )
}

export default BlogsList

const Wrap = styled.div`
  background: #f2f6f8;
  width: 100%;

  padding: 2rem;
`

const Body = styled.div`
  background: white;
  border-radius: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Header = styled.h1`
  z-index: 10;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Avenir Heavy', sans-serif;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  padding: 0 3.5rem;
`

const NewBox = styled(Box)`
  justify-content: flex-start;
  font-weight: ${({ header }) => (header ? 'bold' : 'normal')};
`

const Title = styled.h3`
  font-family: 'Avenir Heavy';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 1rem;
`

const Item = styled.div`
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
`
const PlusDiv = styled(Link)`
  z-index: 10;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin-left: auto;
  background: #5aba5e;
  border-radius: 0.5rem;

  color: white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`