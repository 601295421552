import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 20;
`

export const Modal = styled.div`
  background: #ffffff;
  border-radius: 5px;
  width: 420px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ModalTitle = styled.div`
  padding: 2.5rem;
  width: 100%;
  border-bottom: 1px solid #e7e9ee;
  display: flex;
  justify-content: space-between;

  h3 {
    font-family: 'AvenirLTStd';
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
`

export const ModalBody = styled.form`
  padding: 2.5rem;
  width: 100%;
  height: ${({ h }) => h || 'auto'};
  overflow-x: hidden;
  overflow-y: scroll;
`
