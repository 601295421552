import React from 'react'
import styled from 'styled-components'

import logo from '../../assets/images/logo.svg'
import { ReactComponent as Hamburger } from '../../assets/images/icons/hamburger.svg'
import { ReactComponent as Home } from '../../assets/images/icons/home.svg'
import { ReactComponent as Course } from '../../assets/images/icons/course.svg'
import { ReactComponent as Person } from '../../assets/images/icons/person-green.svg'
import { ReactComponent as Comment } from '../../assets/images/icons/comment.svg'
import { ReactComponent as Tutor } from '../../assets/images/icons/tutor.svg'
import { ReactComponent as Pending } from '../../assets/images/icons/pending.svg'
import { ReactComponent as Enrolled } from '../../assets/images/icons/enrolled.svg'
import { ReactComponent as User } from '../../assets/images/icons/user.svg'
import { NavLink, Link } from 'react-router-dom'

function Sidebar() {
  const [showDropdown, setShowDropdown] = React.useState(false)

  return (
    <Nav>
      <Logo to="/">
        <Hamburger />
        <img src={logo} alt="logo" />
      </Logo>

      <NavItem exact={true} to="/">
        <Home />
        Home
      </NavItem>
      <NavItem to="/courses">
        <Course />
        Courses
      </NavItem>
      <Dropdown onClick={() => setShowDropdown(!showDropdown)}>
        <NavItem as="div">
          <Person />
          Registrations
        </NavItem>
        {showDropdown && (
          <DropdownItems>
            <NavItem to="/enrollment">
              <Enrolled />
              Enrolled
            </NavItem>
            <NavItem to="/registrations">
              <Pending />
              Pending
            </NavItem>
          </DropdownItems>
        )}
      </Dropdown>
      <NavItem to="/feedbacks">
        <Comment />
        Feedback
      </NavItem>
      <NavItem to="/tutors">
        <Tutor />
        Tutors
      </NavItem>
      <NavItem to="/blog">
        <Tutor />
        Blog
      </NavItem>
      <NavItem to="/users">
        <User />
        Admin
      </NavItem>
    </Nav>
  )
}

export { Sidebar }

const Nav = styled.nav`
  width: 175px;
  background: #fff;
  height: 100vh;
  color: ${({ theme: { gaca } }) => gaca.gacaGreen};
  position: fixed;
  overflow: hidden;

  svg {
    margin-right: 2rem;
    max-width: 1.4rem;
  }
`

const Logo = styled(Link)`
  display: flex;
  padding: 3rem 3rem;
`

const Dropdown = styled.div`
  cursor: pointer;
`

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #8a8a8a;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 2rem 3rem;
  transition: background 0.5s;

  svg {
    margin-right: 2rem;
    max-width: 1.4rem;
    fill: #5aba5e;
  }

  &:hover {
    background: #f0f0f0;
    color: #8a8a8a;

    svg {
      fill: #8a8a8a !important;
    }
  }

  &.active {
    background: ${({ theme: { gaca } }) => gaca.gacaGradient};
    color: #fff;

    svg {
      fill: #fff !important;
    }
  }
`

const DropdownItems = styled.div`
  width: 100%;

  a {
    padding: 2rem 0;
    padding-left: 4rem;
  }
`
