import styled from 'styled-components'

const CardBox = styled.div`
  width: 260px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  margin: 2rem 1rem;
`

const Img = styled.img`
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  height: 160px;
  object-fit: cover;
  object-position: center;
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
`

const Category = styled.p`
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: ${({ color, theme: { gaca } }) => gaca[color]};
`

const Tutor = styled.p`
  font-size: 1rem;
  line-height: 1.8rem;
  text-align: right;
  color: #aeaeae;
`

const Title = styled.h3`
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #333;
  width: 90%;
  margin: 2.1rem 0;
  font-weight: ${({ bold }) => (bold ? 500 : 'normal')};
`

export { CardBox, Img, Info, Category, Tutor, Title }
