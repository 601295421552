import instance from './axios'

export const getTutors = async () => {
  const { data } = await instance.get('/tutors')
  return data
}

export const addTutor = async (form, file) => {
  let formData = new FormData(form.current)
  let data = {}
  formData.forEach((value, key) => (data[key] = value))
  console.log(data)

  const tutor = await instance.post('/tutors', data)

  if (file) {
    let imageData = new FormData()

    imageData.append('files', file)
    imageData.append('ref', 'tutor')
    imageData.append('refId', tutor.data.id)
    imageData.append('field', 'image')

    // Upload Image
    let uploadRes = await instance.post('/upload', imageData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    data.image = uploadRes.data[0]
  }
}

export const editTutor = async (tutor, file) => {
  await instance.put(`/tutors/${tutor.id}`, tutor)

  if (file) {
    let imageData = new FormData()

    imageData.append('files', file)
    imageData.append('ref', 'tutor')
    imageData.append('refId', tutor.id)
    imageData.append('field', 'image')

    // Upload Image
    let { data } = await instance.post('/upload', imageData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    tutor.image = data[0]
  }

  return tutor
}

export const deleteTutor = async (id) => {
  instance.delete(`/tutors/${id}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
