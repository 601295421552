import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'

import {
  CourseThumbnail,
  Pagination,
  Loader,
  ErrorOccured,
} from '../components/common'
import { ReactComponent as Plus } from '../assets/images/icons/plus.svg'

import { getCourses } from '../queries/courses'

function Courses() {
  const [visibility, setVisiblity] = useState('')
  const [page, setPage] = useState(1)

  const { isLoading, data, isError } = useQuery(
    ['courses', visibility, page],
    getCourses,
    {
      retry: 1,
    }
  )

  console.log(data, 'data')

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber < 1) return
    setPage(pageNumber)
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <ErrorOccured />
  }

  return (
    <Wrapper>
      <Body>
        <PlusDiv to="/courses/add">
          <Plus style={{ fill: '#fff' }} />
        </PlusDiv>
        <div style={{ width: '100%', maxWidth: '1200px', margin: '3rem auto' }}>
          <Top>
            <Header>Training Courses</Header>
            <SortBox>
              <Sort>
                <span onClick={() => setVisiblity('')}>All</span> ({data.length}
                ){' '}
                <GreenText onClick={() => setVisiblity('PUBLISHED')}>
                  Published
                </GreenText>{' '}
                ({data.filter((c) => c.visibility === 'PUBLISHED').length})
                <GreenText onClick={() => setVisiblity('DRAFT')}>
                  Drafts
                </GreenText>{' '}
                ({data.filter((c) => c.visibility === 'DRAFT').length})
              </Sort>
            </SortBox>
          </Top>
          <Flex>
            {data.map((course) => (
              <CourseThumbnail key={course.id} course={course} />
            ))}
          </Flex>

          <Pagination
            postsPerPage={12}
            currentPage={page}
            totalPosts={15}
            paginate={paginate}
          />
        </div>
      </Body>
    </Wrapper>
  )
}

export default Courses

const Wrapper = styled.div`
  background: #f2f6f8;
  margin: auto;
  padding: 2rem;
`

const Body = styled.div`
  background: white;
  border-radius: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Header = styled.h1`
  z-index: 10;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Avenir Heavy', sans-serif;
`

const PlusDiv = styled(Link)`
  z-index: 10;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin-left: auto;
  background: #5aba5e;
  border-radius: 0.5rem;

  color: white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Sort = styled.div`
  font-size: 1.2rem;

  span,
  p {
    cursor: pointer;
  }
`

const GreenText = styled.p`
  display: inline;
  margin-left: 1rem;
  color: ${({ theme: { gaca } }) => gaca.gacaGreen};
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
`

const SortBox = styled.div``

const Flex = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-wrap: wrap;
  width: 100%;
  margin: 3rem 0;
  justify-content: space-between;
  align-items: flex-start;

  /* & > div:last-of-type {
    margin-right: auto;
  } */
`
