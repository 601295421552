import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import { AuthContext } from '../../contexts/auth'

function Welcome() {
  const {authState} = useContext(AuthContext)

  const [timeOfDay, setTimeOfDay] = useState('')

  useEffect(() => {
    const hour = new Date().getHours()

    if (hour >= 17) {
      setTimeOfDay('Evening')
    } else if (hour >= 12) {
      setTimeOfDay('Afternoon')
    } else {
      setTimeOfDay('Morning')
    }
  }, [])

  return (
    <Greeting>
      Good {timeOfDay}, {authState.username}
      <Clock />
    </Greeting>
  )
}

export { Welcome }

function Clock() {
  const [currentTime, setTime] = React.useState('')

  React.useEffect(() => {
    let timeout

    function showTime() {
      let date = new Date()
      let h = date.getHours() // 0 - 23
      let m = date.getMinutes() // 0 - 59
      let s = date.getSeconds() // 0 - 59
      let session = 'AM'

      if (h === 0) {
        h = 12
      }

      if (h > 12) {
        h = h - 12
        session = 'PM'
      }

      h = h < 10 ? '0' + h : h
      m = m < 10 ? '0' + m : m
      s = s < 10 ? '0' + s : s

      let time = h + ':' + m + ':' + s + ' ' + session

      setTime(time)

      timeout = setTimeout(showTime, 1000)
    }

    showTime()

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return <span>{currentTime}</span>
}

const Greeting = styled.h1`
  color: #000;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 29px;
  letter-spacing: -0.022rem;
  margin-bottom: 3rem;
  font-family: 'Avenir Heavy', sans-serif;

  span {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({ theme: { gaca } }) => gaca.silverGrey};
    display: inline-block;
    padding-left: 2rem;
  }
`
