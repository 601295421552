import React, { useState } from 'react'
import styled from 'styled-components'
import { CardBox, Img, Info, Category, Title } from './CardUI'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import courseImg from '../../assets/images/courseimg.jpeg'
import pencil from '../../assets/images/icons/pencil.svg'
import option from '../../assets/images/icons/option.svg'

import { truncateString } from '../../utils'
import { deleteCourse } from '../../queries/courses'

function CourseThumbnail({ course }) {
  const [showOption, setShowOption] = useState(false)

  const deleteHandelr = async () => {
    await deleteCourse(course.id)
    toast('Course deleted successfully')
  }

  return (
    <CardBox>
      <Img src={course.image?.url || courseImg} alt="course image" />
      <Details>
        <Info>
          <Category color="silverGrey">{course.category.name} course</Category>
        </Info>
        <Title bold={true}>{truncateString(course.title)}</Title>
      </Details>
      <Actions>
        <Options>
          <img onClick={() => setShowOption(!showOption)} src={option} alt="" />
          <div style={{ display: showOption ? 'block' : 'none' }}>
            <p style={{ color: '#5ABA5E' }}>Move to drafts </p>
            <p onClick={deleteHandelr}>Delete course</p>
          </div>
        </Options>
        <Edit>
          <Link to={`/courses/edit/${course.id}`}>
            <img src={pencil} alt="pencil icon" />
          </Link>
        </Edit>
      </Actions>
    </CardBox>
  )
}

export { CourseThumbnail }

const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.8rem;
  position: relative;
  cursor: pointer;

  div {
    padding: 1rem;
    border: 0.5px solid rgba(232, 232, 232, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(51, 51, 51, 0.1);
    border-radius: 2px;
    position: absolute;
    top: 35px;
    z-index: 100;
    width: 95px;
    left: -10px;
  }

  p {
    font-size: 1rem;
    line-height: 1.8;
    cursor: pointer;
  }

  img {
    cursor: pointer;
    padding: 0 1rem;
  }
`

const Details = styled.div`
  padding: 0 2rem;
  height: 100px;
`

const Actions = styled.div`
  height: 55px;
  max-height: 55px;
  border-top: 0.75px solid #e7e9ee;
  display: flex;
  padding-left: 2rem;
`

const centerd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Edit = styled(centerd)`
  background: ${({ theme: { gaca } }) => gaca.gacaGreen};
  width: 65px;
  height: 100%;
  margin-left: auto;
`
